<template>
  <div :class="classes" class="d-flex">
    <div class="pa-2 rounded text-wrap text-wrapper" :class="isCurrentUserSender ?  isDeleted ? 'ml-auto' : '' : 'ml-1'">
      <template v-if="isDeleted">
        <span class="font-italic">[{{ $t('chatWindow.messageDeleted') }}] [{{ $t('chatWindow.audio') }}]</span>
      </template>
      <template v-else>
        <div class="d-inline-flex align-center text-message pointer" @click="audioClick">
          <v-icon small color="black">uil uil-import</v-icon>
          <span class="text-decoration-underline">{{ $t('chatWindow.audio') }}</span>
        </div>
      </template>
    </div>
    <div v-if="!isDeleted" class="d-flex align-center" :class="isCurrentUserSender ? 'order-first ml-auto' : ''">
      <v-btn v-if="showMessageOptions" icon small @click="openMenu">
        <v-icon color="separator">uil uil-ellipsis-v</v-icon>
      </v-btn>
      <v-menu v-model="showMenu" absolute offset-y :position-x="menuPosition.x" :position-y="menuPosition.y" class="menu">
        <v-list class="py-0">
          <v-list-item v-if="canTranslate" dense @click="translateMessage">
            <v-icon small class="mr-2">$translate</v-icon>
            {{ $t('chatWindow.translateMessage') }}
          </v-list-item>
          <v-list-item v-if="canDelete" dense @click="deleteMessage">
            <v-icon small>uil uil-trash</v-icon>
            {{ $t('chatWindow.deleteMessage') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import Services from '@/services'
import { deleteMessage } from '@/signalr/chat.js'
export default {
  components: {},
  props: {
    value: Object,
    threadId: String,
    allowTranslate: Boolean,
    idToUseAsCurrentUser: String
  },
  data () {
    return {
      showMenu: false,
      menuPosition: {
        x: 0,
        y: 0
      }
    }
  },
  computed: {
    othreUserMessage () {
      return this.value?.users?.find((el) => el.id !== this.currentUserId) ?? {}
    },
    isCurrentUserSender () {
      return this.currentUserId === this.value.sender
    },
    currentUserId () {
      if (this.idToUseAsCurrentUser) {
        return this.idToUseAsCurrentUser
      }
      return this.$store.getters.userCommonData.id
    },
    classes () {
      let classes = 'message'
      if (this.isCurrentUserSender) {
        classes += ' current-user-sender'
      }
      return classes
    },
    isDeleted () {
      return this.value.deleted
    },
    canDelete () {
      return this.isCurrentUserSender
    },
    showMessageOptions () {
      return this.canDelete
    }
  },
  methods: {
    async audioClick () {
      const response = await Services.getFile('Chat/GetChatAudioFile', {
        threadId: this.threadId,
        fileName: this.value.audio.name
      })
      const blob = new Blob([response.data])
      const fileUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = fileUrl
      link.download = this.value.audio.name
      link.click()
      URL.revokeObjectURL(link.href)
    },
    deleteMessage () {
      deleteMessage(this.threadId, this.value.id)
    },
    openMenu (e) {
      this.showMenu = true
      this.menuPosition.x = e.clientX
      this.menuPosition.y = e.clientY
    }
  }
}
</script>
