<template>
  <v-data-table
    ref="dataTable"
    :items="filteredItems"
    :headers="headers"
    :multi-sort="multiSort"
    :search="serverPagination ? '' : search"
    :loading="loading || loadingData"
    :showSelect="showSelect"
    :page="page"
    @click:row="$emit('click:row', $event)"
    @pagination="pagination"
    @update:options="updateOptions"
    hide-default-footer
    :disable-pagination="disablePagination"
    :server-items-length="serverPagination ? totalItems : -1"
    :disable-sort="disableSort"
    :footer-props="{ itemsPerPageOptions: [15, 25, 50, 100, -1] }"
    :item-class="itemClass"
    :show-expand="showExpand"
    :expand-icon="expandIcon"
    :item-key="itemKey"
    :item-value="itemValue"
    :single-expand="singleExpand"
    @item-expanded="$emit('item-expanded', $event)"
  >
    <template v-slot:top v-if="enableFilters">
      <table style="width: 100%">
        <tbody>
          <tr>
            <td colspan="100%">
              <v-row>
                <v-col class="d-flex">
                  <v-text-field
                    class="ma-auto"
                    :label="$t('common.search')"
                    :value="search"
                    prepend-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
                <v-col md="2" cols="6" class="d-flex">
                  <v-btn
                    rounded
                    color="error"
                    @click="clearFilters"
                    class="ma-auto"
                    >{{ $t('companies.clearFilters') }}</v-btn
                  >
                </v-col>
              </v-row>
            </td>
          </tr>
          <tr v-if="enableFilters">
            <td
              class="pr-2 pl-2"
              v-for="(header, index1) in headersWithFilter"
              :key="index1"
            >
              <v-select
                :label="header.text"
                v-model="header.myFilter"
                multiple
                :items="availableValues(header.value)"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption"
                    >(+{{ header.myFilter.length - 1 }} {{ $t('common.others') }})</span
                  >
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template
      v-for="field in Object.keys($scopedSlots)"
      v-slot:[field]="{ item, headers }"
    >
      <slot :name="field" :item="item" :headers="headers" />
    </template>
    <template v-slot:footer v-if="!disablePagination && pageCount > 1">
      <v-pagination v-model="page" :length="pageCount"> </v-pagination>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    items: Array,
    headers: Array,
    loading: Boolean,
    showSelect: Boolean,
    disablePagination: Boolean,
    serverPagination: Boolean,
    endpoint: String,
    enableFilters: Boolean,
    disableSort: Boolean,
    itemClass: [String, Function],
    search: String,
    embededSearch: Boolean,
    multiSort: Boolean,
    showExpand: Boolean,
    expandIcon: {
      type: String,
      default: '$sort'
    },
    itemKey: String,
    itemValue: String,
    additionalParams: Object,
    mappingFunction: Function,
    singleExpand: Boolean
  },
  data () {
    return {
      itemsPerPage: 0,
      page: 1,
      paginationItems: [],
      totalItems: 0,
      sortProperties: [],
      getDataTimeout: null,
      loadingData: false
    }
  },
  watch: {
    search (newVal) {
      this.searchUp(newVal)
    },
    additionalParams () {
      this.getDataDebounce()
    }
  },
  computed: {
    pageCount () {
      try {
        return Math.ceil(this.totalItems / this.itemsPerPage)
      } catch {
        return 1
      }
    },
    tableItems () {
      return this.serverPagination ? this.paginationItems : this.items
    },
    headersWithFilter () {
      if (!this.headers) {
        return []
      }
      return this.headers.filter(
        (el) => !el.disableCustomFiltering && el.value !== 'actions'
      )
    },
    filteredItems () {
      if (this.serverPagination) {
        return this.tableItems
      }
      if (!this.items) {
        return []
      }
      if (this.loading) {
        return []
      }
      if (!this.headers) {
        return []
      }
      return this.items.filter((el) => {
        let result = true
        this.headers.forEach((header) => {
          if (header.myFilter && header.myFilter.length > 0) {
            if (!header.myFilter.includes(el[header.value])) {
              result = false
            }
          }
        })
        return result
      })
    }
  },
  methods: {
    async updateOptions (options) {
      this.sortProperties = options.sortBy.map((element, index) => {
        return {
          propertyName: element,
          priority: index,
          type: options.sortDesc[index] ? 1 : 0
        }
      })
      await this.getDataDebounce(0)
    },
    searchUp (input) {
      this.search = input
      this.page = 1
      this.getDataDebounce()
    },
    async getDataDebounce (timeout) {
      if (isNaN(timeout) || timeout < 0) {
        timeout = 250
      }
      clearTimeout(this.getDataTimeout)
      this.getDataTimeout = setTimeout(this.getData, timeout)
    },
    async getData () {
      if (!this.serverPagination) {
        return
      }
      let url
      if (this.endpoint.includes('?')) {
        url = this.endpoint + '&'
      } else {
        url = this.endpoint + '?'
      }

      url += `page=${this.page}&pageSize=${this.itemsPerPage}`

      if (this.search) {
        url += `&search=${this.search}`
      }

      if (this.sortProperties) {
        url += '&' + this.getStringSortableProperties()
      }

      if (this.additionalParams) {
        Object.keys(this.additionalParams).forEach((key) => {
          if (Array.isArray(this.additionalParams[key])) {
            this.additionalParams[key].forEach((element) => {
              url += `&${key}=${element}`
            })
          } else {
            url += `&${key}=${this.additionalParams[key]}`
          }
        })
      }

      this.loadingData = true

      const response = await this.$http.get(url)

      if (this.mappingFunction) {
        this.paginationItems = this.mappingFunction(response.data.results)
      } else {
        this.paginationItems = response.data.results
      }
      this.totalItems = response.data.count
      this.loadingData = false
      this.$emit('itemsLoaded', this.paginationItems)
    },
    getStringSortableProperties () {
      let result = ''
      this.sortProperties.forEach((item, index) => {
        if (result.length > 0) {
          result += '&'
        }
        const name = `sortableProperties[${index}]`
        result += `${name}.type=${item.type}&${name}.propertyName=${item.propertyName}&${name}.priority=${item.priority}`
      })
      return result
    },
    pagination (options) {
      this.page = options.page
      this.itemsPerPage = options.itemsPerPage
    },
    clearFilters () {
      this.headers.forEach((el) => (el.myFilter = []))
      this.search = ''
    },
    availableValues (propName) {
      return this.tableItems
        .map((el) => el[propName])
        .filter((v, i, a) => a.indexOf(v) === i)
    }
  },
  destroyed () {
    clearTimeout(this.searchDebounce)
  }
}
</script>

<style scoped>
/* .theme--light.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
.theme--dark.v-data-table >>> .v-data-table__wrapper tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.5);
} */
</style>
<style>
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon,
.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon {
  margin-left: 10px;
  padding: 1px 0;
}

.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon,
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon:hover,
.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon:hover {
  color: var(--v-primary-base);
}

th span {
  font-weight: normal;
  color: black;
}

.theme--light.v-data-table {
  color: black;
}

.theme--light.v-data-table td {
  border-bottom: thin solid var(--v-background-base) !important;
}
</style>
