<template>
    <v-dialog v-model="dialog" width="550" persistent>
    <v-card>
      <v-card-title> {{ $t('chatWindow.sendFileDialog.title') }} </v-card-title>
      <v-card-text>
        <v-form v-model="form" ref="form">
          <label>{{ $t('chatWindow.sendFileDialog.uploadFile') }}</label>
          <v-file-input
            dense
            :disabled="loading"
            :loading="loading"
            v-model="file"
            :rules="rules.file"
            required
            :accept="acceptedTypes"
            outlined
            prepend-icon
          ></v-file-input>
          <label>{{ $t('chatWindow.sendFileDialog.fileNameLabel') }}</label>
          <v-text-field
            outlined
            :disabled="loading"
            :loading="loading"
            v-model="fileName"
            :rules="rules.name"
            dense
            required
          >
          </v-text-field
        ></v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn :disabled="loading" :loading="loading" color="primary" @click="send" depressed>{{ $t('common.send') }}</v-btn>
        <v-btn :disabled="loading" :loading="loading" outlined @click="close">{{ $t('common.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Services from '@/services'
export default {
  data () {
    return {
      form: null,
      dialog: false,
      file: null,
      fileName: '',
      loading: false,
      acceptedTypes:
        'image/png, image/jpeg, image/jpg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      rules: {
        name: [(v) => !!v || this.$t('validation.fieldRequired')],
        file: [
          (v) => !!v || this.$t('validation.fieldRequired'),
          (v) =>
            this.acceptedTypes.includes(v?.type) ||
            this.$t('validation.unsupportedFileType')
        ]
      }
    }
  },
  props: {
    conversationId: String,
    threadId: String
  },
  methods: {
    open () {
      this.file = null
      this.form = false
      this.fileName = ''
      this.dialog = true
      this.$nextTick(() => {
        this.$refs.form.reset()
      })
    },
    close () {
      this.dialog = false
      this.file = null
    },
    async send () {
      this.$refs.form.validate()
      if (!this.form) {
        return
      }
      this.loading = true
      try {
        const formData = new FormData()
        formData.append('ThreadId', this.threadId)
        formData.append('Files[0].Name', this.fileName)
        formData.append('Files[0].File', this.file, this.file.name)
        await Services.post('Chat/UploadChatFile', formData)
        this.close()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
