<template>
<div>
  <v-divider/>
  <v-row class="my-2" no-gutters>
    <v-col cols="4">
      <h4 class="primary--text">{{ details.companyName }}</h4>
      <p>
        {{ details.displayId }}<br/>
        {{ $t('offerDetails.vat')}} {{ details.vatNumber}}
      </p>
      <h4 class="primary--text">{{ $t('offerDetails.address') }}:</h4>
      <p>
        {{ details.street }}<br/>
        {{ details.zipCode }} {{ details.city }}, {{ details.country }}
      </p>
    </v-col>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.averageRating') }}:</h4>
      <p><v-rating :value="details.averageRating" color="accent" size="16" small readonly></v-rating></p>
      <h4 class="primary--text">{{ $t('offerDetails.paymentRating') }}:</h4>
      <p><v-rating :value="details.averagePaymentRating" color="accent" size="16" small readonly></v-rating></p>
    </v-col>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.documents') }}:</h4>
      <div v-for="(document, index) in details.documentNames" :key="index" class="my-2 pointer" @click="getFile(document.fileName)">
        <v-icon color="lightText" size="16" class="mr-2">$download</v-icon>
        {{ fileName(document.displayName) }}
      </div>
      <div v-if="details.documentNames.length === 0" class="text-subtitle-2 font-weight-regular">
        {{ $t('common.none') }}
      </div>
    </v-col>
  </v-row>
</div>
</template>

<script>
import Service from '@/services'
export default {
  name: 'CompanyInfoPanel',
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  computed: {
    usersCompanyId () {
      return this.$store.getters.userCommonData.companyId
    }
  },
  methods: {
    fileName (name) {
      var length = name.length
      if (length > 40) {
        return name.slice(0, 20) + '...' + name.slice(length - 10, length)
      }
      return name
    },
    async getFile (file) {
      try {
        const { data } = await Service.getFile(
          '/Company/GetCompanyDocumentFile',
          {
            companyId: this.details.companyId,
            fileName: file
          }
        )

        const blob = new Blob([data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = file
        link.click()
        URL.revokeObjectURL(link.href)
      } catch (e) {}
    }
  }
}
</script>
