<template>
  <div class="chat-wrapper" :style="styles" v-if="!isUnpined && isEnable">
    <div class="d-flex align-end chat-wrapper-conversations">
      <MoreChatWindows
        v-if="normalWindows.length > maxChatWindows"
        :conversations="moreConversations"
      />
      <ChatWindow
        ref="conversations"
        v-for="(window, index) in mainConversations"
        :key="`${index}-${window.id}`"
        :conversationId="window.id"
        :currentThreadId="window.currentThreadId"
        class="mx-1"
      ></ChatWindow>
    </div>
  </div>
</template>

<script>
import ChatWindow from './Window/ChatWindow.vue'
import { stop } from '@/signalr/chat.js'
import * as types from '@/store/modules/chat/chat-mutation-types'
import MoreChatWindows from '@/chat/MoreChatWindows'
import { generateString } from '@/utils/helpers'
export default {
  components: {
    ChatWindow,
    MoreChatWindows
  },
  data () {
    return {}
  },
  computed: {
    isEnable () {
      return this.$store.getters['chat/enable']
    },
    windows () {
      return this.$store.getters['chat/windows']
    },
    normalWindows () {
      return this.windows.filter((el) => !el.unpined)
    },
    isUnpined () {
      return this.$route.path.includes('unpined-chat')
    },
    styles () {
      const position = this.$store.getters['chat/position']
      const result = {}

      if (position.right !== null && position.right >= 0) {
        result.right = `${position.right}px`
      }

      if (position.left !== null && position.left >= 0) {
        result.left = `${position.left}px`
      }

      return result
    },
    storeConversations () {
      return this.$store.getters['chat/conversations']
    },
    maxChatWindows () {
      return this.$store.getters['chat/maxChatWindows']
    },
    focusedConversationId () {
      return this.$store.getters['chat/focusedConversationId']
    },
    mainConversations () {
      // return this.conversations.slice(1, this.maxChatWindows)
      return this.normalWindows.slice(0, this.maxChatWindows)
    },
    moreConversations () {
      return this.normalWindows.slice(this.maxChatWindows)
    },
    settings () {
      return this.$store.getters['chat/settings']
    },
    autostart () {
      return this.$store.getters['chat/autostart']
    }
  },
  created () {
    window.addEventListener('resize', this.resizeBrowser, true)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeBrowser, true)
  },
  methods: {
    resizeBrowser () {
      this.$store.commit(`chat/${types.SET_BROWSER_WIDTH}`, window.innerWidth)
    },
    generateString
  },
  destroyed () {
    stop()
  }
}
</script>

<style scoped lang="scss">
.chat-wrapper {
  position: fixed;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  bottom: 14px;
  z-index: 199;
  height: 0;
}
</style>
