import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pl from 'vuetify/lib/locale/pl'
import en from 'vuetify/lib/locale/en'
import dashboard from '@/components/icons/dashboard.vue'
import rating from '@/components/icons/rating.vue'
import market from '@/components/icons/market.vue'
import company from '@/components/icons/company.vue'
import clients from '@/components/icons/clients.vue'
import settings from '@/components/icons/settings.vue'
import archive from '@/components/icons/archive.vue'
import chat from '@/components/icons/chat.vue'
import chatMsg from '@/components/icons/chatMsg.vue'
import notifications from '@/components/icons/notifications.vue'
import sort from '@/components/icons/sort.vue'
import filter from '@/components/icons/filter.vue'
import arrowLeft from '@/components/icons/arrow-left-outlined.vue'
import arrowRight from '@/components/icons/arrow-right-outlined.vue'
import privateIcon from '@/components/icons/private.vue'
import hidden from '@/components/icons/hidden.vue'
import conversation from '@/components/icons/conversation.vue'
import truck from '@/components/icons/truck.vue'
import top from '@/components/icons/top.vue'
import trash from '@/components/icons/trash.vue'
import calendar from '@/components/icons/calendar.vue'
import checkboxOff from '@/components/icons/checkboxOff.vue'
import checkboxOn from '@/components/icons/checkboxOn.vue'
import close from '@/components/icons/close-outlined.vue'
import expand from '@/components/icons/expand.vue'
import add from '@/components/icons/add.vue'
import remove from '@/components/icons/remove.vue'
import download from '@/components/icons/download.vue'
import cross from '@/components/icons/cross.vue'
import arrows from '@/components/icons/arrows.vue'
import magnify from '@/components/icons/magnify.vue'
import client from '@/components/icons/client.vue'
import provider from '@/components/icons/provider.vue'
import contacts from '@/components/icons/contacts.vue'
import vehicles from '@/components/icons/vehicles.vue'
import freights from '@/components/icons/freights.vue'
import others from '@/components/icons/others.vue'
import last from '@/components/icons/last.vue'
import packageIcon from '@/components/icons/package.vue'
import closeIcon from '@/components/icons/close-icon.vue'
import back from '@/components/icons/back.vue'
import down from '@/components/icons/down.vue'
import maximalize from '@/components/icons/maximalize-outlined.vue'
import minimalize from '@/components/icons/minimalize-outlined.vue'
import user from '@/components/icons/user-chat.vue'
import arrowsLeft from '@/components/icons/arrows-left-outlined.vue'
import arrowsRight from '@/components/icons/arrows-right-outlined.vue'
import infoBox from '@/components/icons/info-box.vue'
import deal from '@/components/icons/deal.vue'
import bot from '@/components/icons/boot.vue'
import translate from '@/components/icons/translate.vue'
import send from '@/components/icons/send.vue'
import paperclip from '@/components/icons/paperclip.vue'
import localization from '@/components/icons/localization.vue'
import emoji from '@/components/icons/emoji.vue'
import ban from '@/components/icons/ban.vue'
import sound from '@/components/icons/sound.vue'
import Star from '@/components/icons/Star.vue'
import Empty from '@/components/icons/StarEmpty.vue'
import phone from '@/components/icons/phone.vue'
import mail from '@/components/icons/mail.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#006446',
        secondary: '#323b3d',
        accent: '#76B62A',
        error: '#d32f2f',
        info: '#3f7ebe',
        success: '#689f38',
        warning: '#fbc02d',
        background: '#f0f0f0',
        backgroundHover: '#82BEAA',
        backgroundChat: '#f5f5f5',
        stateChange: '#d0ebf7',
        expansionPanelActive: '#666',
        separator: '#bebebe',
        lightText: '#787878',
        deal: '#e6e6e6'
      }
    }
  },
  icons: {
    values: {
      phone: { component: phone },
      mail: { component: mail },
      dashboard: { component: dashboard },
      rating: { component: rating },
      market: { component: market },
      company: { component: company },
      clients: { component: clients },
      settings: { component: settings },
      archive: { component: archive },
      chat: { component: chat },
      chatMsg: { component: chatMsg },
      notifications: { component: notifications },
      sort: { component: sort },
      dropdown: { component: sort },
      // expand: { component: sort },
      expand: { component: expand },
      filter: { component: filter },
      arrowLeft: { component: arrowLeft },
      arrowRight: { component: arrowRight },
      private: { component: privateIcon },
      hidden: { component: hidden },
      conversation: { component: conversation },
      truck: { component: truck },
      top: { component: top },
      trash: { component: trash },
      calendar: { component: calendar },
      checkboxOff: { component: checkboxOff },
      checkboxOn: { component: checkboxOn },
      close: { component: close },
      add: { component: add },
      remove: { component: remove },
      download: { component: download },
      cross: { component: cross },
      arrows: { component: arrows },
      magnify: { component: magnify },
      client: { component: client },
      provider: { component: provider },
      contacts: { component: contacts },
      vehicles: { component: vehicles },
      freights: { component: freights },
      others: { component: others },
      last: { component: last },
      package: { component: packageIcon },
      closeIcon: { component: closeIcon },
      back: { component: back },
      down: { component: down },
      maximalize: { component: maximalize },
      minimalize: { component: minimalize },
      user: { component: user },
      arrowsLeft: { component: arrowsLeft },
      arrowsRight: { component: arrowsRight },
      infoBox: { component: infoBox },
      deal: { component: deal },
      bot: { component: bot },
      translate: { component: translate },
      send: { component: send },
      paperclip: { component: paperclip },
      localization: { component: localization },
      emoji: { component: emoji },
      ban: { component: ban },
      ratingFull: { component: Star },
      ratingEmpty: { component: Empty },
      sound: { component: sound }
    }
  },
  lang: {
    locales: {
      pl,
      en
    },
    current: 'pl'
  }
})
