<template>
    <v-dialog v-model="dialog" width="550" persistent>
    <v-card v-if="offer">
      <v-card-title>
        {{ $t('acceptDeal.title') }} {{ offer.price }}
        {{ currency }}?</v-card-title
      >
      <v-card-actions class="d-flex justify-center">
        <v-btn :disabled="loading" :loading="loading" @click="accept" color="primary" depressed>{{ $t('common.save') }}</v-btn>
        <v-btn :disabled="loading" @click="close" color="primary" outlined>{{ $t('common.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      offer: null,
      loading: false
    }
  },
  computed: {
    currency () {
      if (!this.offer) {
        return ''
      }
      return this.allCurrency.find((el) => el.id === this.offer?.currency)
        ?.description
    },
    allCurrency () {
      return this.$store.getters.enums.currency
    }
  },
  methods: {
    open (offer) {
      this.offer = JSON.parse(JSON.stringify(offer))
      this.loading = false
      this.dialog = true
    },
    accept () {
      this.loading = true
      this.$emit('accept', this.offer)
    },
    close () {
      this.dialog = false
      this.loading = false
      this.offer = null
    }
  }
}
</script>
