<template>
  <v-navigation-drawer
    v-model="drawerModel"
    width="300"
    :temporary="!dockChat"
    disable-resize-watcher
    clipped
    height="100%"
    right
    app
    mobile-breakpoint="1500"
    ref="navigation"
  >
    <Chat v-if="drawer" :id="dockChat ? 'dockChat' : 'chat'" @hide="$emit('chat-drawer-change', false)" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import Chat from './ChatSidebar/Chat.vue'
import { startConversation } from '@/signalr/chat'
export default {
  name: 'ChatDrawer',
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  components: { Chat },
  mounted () {
    this.$watch(
      () => {
        return this.$refs.navigation.isActive
      },
      (val) => {
        if (!val && !this.dockChat) {
          this.$emit('chat-drawer-change', false)
        }
      }
    )
  },
  computed: {
    ...mapGetters({
      users: 'users/users'
    }),
    dockChat () {
      return this.$store.getters.userCommonData.dockChat
    },
    drawerModel: {
      get () {
        return this.drawer
      },
      set (value) {
        this.$emit('drawer-change', value)
      }
    }
  },
  methods: {
    startConversationWithUser (userId) {
      startConversation(userId)
    }
  }
}
</script>
