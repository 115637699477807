<template>
  <div class="cooperation-suspended d-flex justify-center white--text font-weight-bold py-1">
    <span>{{ $t('cooperationSuspended.title') }}</span>
  </div>
</template>

<script>
export default {
  name: 'CooperationSuspended'
}
</script>

<style scoped>
.cooperation-suspended {
  background-color: rgb(180, 2, 0);
}
</style>
