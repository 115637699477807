import moment from 'moment'

export const log = (title, ...data) => {
  const availableUrl = ['localhost', 'test.24tsl.eu']

  if (!availableUrl.includes(window.location.hostname)) {
    return
  }

  const now = moment().format('DD.MM.YYYY HH:mm:ss')

  console.groupCollapsed(`[${now}] - ${title}`)
  data.forEach(item => {
    console.log(item)
  })
  console.groupEnd()
}
