<template>
  <div class="main-content">
    <MultiThread v-if="!currentThreadId && !isSupportThread" :conversation="conversation" />
    <div v-else class="d-flex  flex-column h-100">
      <div v-if="!isSupportThread && currentThreadId" class="d-flex justify-space-between   pointer px-3 shadow-bottom" style="z-index: 1; height: 50px">
        <div class="d-flex align-center pointer " @click="returnToMultiThread">
          <v-icon size="25" color="primary" class="mr-3">$arrowsLeft</v-icon>
          <div class="d-flex flex-column">
            <h4 class="font-weight-semibold">{{ getConversationType(thread) }}</h4>
            <span class="body-2">{{ getThreadName(thread) }}</span>
          </div>
        </div>
        <div v-if="thread.associatedItem" class="d-flex align-center pointer "  @click="openDetails(thread)">
          <v-icon size="25" color="primary" class="mr-3">$infoBox</v-icon>
        </div>
      </div>
      <ChatThread
        v-if="currentThreadId"
        :conversation="conversation"
        :threadId="currentThreadId"
        :canSetAsRead="true"
        :isOpen="isOpen"
      ></ChatThread>
    </div>
    <OfferDetailsDialog
      v-if="vehicleId"
      :value="offerDetailsActive"
      :itemId="vehicleId"
      :isVehicle="thread.type === 3 || thread.type === 1"
      @closed="closeOfferDetailsDialog"
    />
  </div>
</template>

<script>
import ChatThread from './ChatThread.vue'
import MultiThread from './MultiThread.vue'
import Messenger from '@/utils/messenger.js'
import OfferDetailsDialog from '@/views/Market/dialogs/OfferDetailsDialog.vue'

export default {
  components: {
    ChatThread,
    MultiThread,
    OfferDetailsDialog
  },
  data () {
    return {
      vehicleId: null,
      offerDetailsActive: false
    }
  },
  props: {
    conversationId: String,
    currentThreadId: String,
    isSupportThread: Boolean,
    isOpen: Boolean
  },
  computed: {
    thread () {
      return this.conversation.threads.find(
        (el) => el.id === this.currentThreadId
      )
    },
    window () {
      return this.$store.getters['chat/windows'].find(
        (el) => el.id === this.conversationId && el.currentThreadId === this.currentThreadId
      )
    },
    conversation () {
      return this.window?.conversation
    },
    conversationThreadTypes () {
      return this.$store.getters.enums.conversationThreadType
    }
  },
  methods: {
    openDetails (item) {
      this.$store.commit('market/MARK_SHOW_OFFER', item)
      this.vehicleId = item.associatedItem
      this.offerDetailsActive = true
    },
    closeOfferDetailsDialog () {
      this.offerDetailsActive = false
      this.vehicleId = null
    },
    changeThread () {
      if (this.$store.getters['chat/isUnpinedWindow']) {
        Messenger.canIChangeThread(this.conversationId, this.currentThreadId, null)
      } else {
        this.$store.dispatch('chat/changeCurrentThreadInWindow', {
          conversationId: this.conversationId,
          currentThreadId: this.currentThreadId,
          threadId: null
        })
      }
    },
    returnToMultiThread () {
      if (this.isConverstaionWithSupport) {
        return
      }
      this.changeThread()
    },
    getConversationType (thread) {
      if (thread == null) {
        return ''
      }
      return this.conversationThreadTypes.find((el) => el.id === thread.type)
        ?.description
    },
    getThreadName (thread) {
      if (thread?.type === 0) {
        return ''
      }
      return thread?.name
    }
  }
}
</script>
<style scoped>
.main-content {
  height: 100%;
  overflow: hidden;
}
</style>
