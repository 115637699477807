import Service from '@/services/'
import { register, unregister } from 'register-service-worker'

export const registerWebPushServiceWorker = () => {
  // Service worker registration does not work if env is not set to the production
  if (process.env.NODE_ENV === 'production') {
    Notification.requestPermission().then((status) => {
      if (status === 'granted') {
        registerServiceWorker()
      }
    })
  }
}

export const unregisterWebPushServiceWorker = () => {
  unregister()
}

const registerServiceWorker = () => {
  register(`${process.env.BASE_URL}web-push-service-worker.js`, {
    ready (registration) {
      registration.pushManager.getSubscription()
        .then(async (subscription) => {
          if (subscription) {
            return subscription
          }

          const { data } = await Service.get('/WebPush/GetWebPushPublicKey')

          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(data)
          })
        })
        .then(subscription => {
          Service.post('/WebPush/AcceptWebPush', {
            pushEndpoint: subscription.endpoint,
            pushP256DH: base64Encode(subscription.getKey('p256dh')),
            pushAuth: base64Encode(subscription.getKey('auth')),
            browserName: getBrowserName()
          })
        })
    }
  })
}

const getBrowserName = () => {
  // Get the user-agent string
  const userAgentString = navigator.userAgent

  // Detect Internet Explorer
  if (userAgentString.indexOf('MSIE') > -1) {
    return 'ie'
  }

  // Detect Firefox
  if (userAgentString.indexOf('Firefox') > -1) {
    return 'firefox'
  }

  // Detect Edge
  if (userAgentString.indexOf('Edg') > -1) {
    return 'edge'
  }

  // Detect Chrome
  const isChrome = userAgentString.indexOf('Chrome') > -1
  // Detect Safari
  const isSafari = userAgentString.indexOf('Safari') > -1

  // Discard Safari since it also matches Chrome
  if (!isChrome && isSafari) {
    return 'safari'
  }

  // Detect Opera
  const isOpera = userAgentString.indexOf('OP') > -1
  // Discard Chrome since it also matches Opera
  if (isChrome && isOpera) {
    return 'opera'
  }

  return 'chrome'
}

const urlBase64ToUint8Array = (base64String) => {
  var padding = '='.repeat((4 - base64String.length % 4) % 4)
  var base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  var rawData = window.atob(base64)
  var outputArray = new Uint8Array(rawData.length)

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}

const base64Encode = (arrayBuffer) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)))
}
