<template>
<div>
  <v-divider/>
  <div class="mt-4">
    <div class="map" id="map"></div>
  </div>
  <div class="d-flex justify-center">
    <a v-if="mapLink" :href="mapLink" target="_blank">
      <v-btn color="primary" text>{{ $t('offerDetails.open') }}</v-btn>
    </a>
  </div>
</div>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

export default {
  name: 'MapPanel',
  components: {},
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      mapLink: null
    }
  },
  mounted () {
    this.createMap()
  },
  computed: {},
  methods: {
    createMap () {
      var url = 'https://www.google.com/maps/dir/'
      this.map = L.map('map')
      this.map.zoomControl.setPosition('bottomright')

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map)

      var arrayOfLatLngs = []

      for (let i = 0; i < this.details.loadingPlaces.length; i++) {
        const loading = this.details.loadingPlaces[i]
        url = url.concat(loading.place.latitude, ',', loading.place.longitude, '/')
        arrayOfLatLngs.push([loading.place.latitude, loading.place.longitude])
        L.marker([loading.place.latitude, loading.place.longitude], {
          icon: L.divIcon({
            className: 'loading-icon',
            html: i + 1,
            iconSize: [15, 19],
            iconAnchor: [10, 20]
          })
        }).addTo(this.map)
      }

      for (let i = 0; i < this.details.unloadingPlaces.length; i++) {
        const unloading = this.details.unloadingPlaces[i]
        url = url.concat(unloading.place.latitude, ',', unloading.place.longitude, '/')
        arrayOfLatLngs.push([unloading.place.latitude, unloading.place.longitude])
        L.marker([unloading.place.latitude, unloading.place.longitude], {
          icon: L.divIcon({
            className: 'unloading-icon',
            html: i + 1,
            iconSize: [15, 19],
            iconAnchor: [10, 20]
          })
        }).addTo(this.map)
      }

      const bounds = new L.LatLngBounds(arrayOfLatLngs).pad(0.1)
      window.dispatchEvent(new Event('resize'))

      setTimeout(() => {
        this.map.fitBounds(bounds)
      }, 100)

      // const center = bounds.getCenter()
      // const zoom = this.map.getBoundsZoom(bounds)

      // this.mapLink = url.concat('/@', center.lat, ',', center.lng, ',', zoom === 0 ? zoom : 5, 'z')
      // this.mapLink = url.concat('/@', center.lat, ',', center.lng)
      this.mapLink = url
      console.log(this.mapLink)
    }
  }
}
</script>
<style>
.map {
  height: 250px;
  width: 100%;
  position: relative;
}
.loading-icon {
  background: url('~@/assets/images/map_pin.svg');
  text-align: center;
  padding-top: 2px;
  color: White;
}
.unloading-icon {
  background: url('~@/assets/images/lime_map_pin.svg');
  text-align: center;
  padding-top: 2px;
  color: white;
}
</style>
