<template>
<div @mouseover="upHere = true" @mouseleave="upHere = false">
  <svg v-if="!upHere" id="info_box" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25" class="icon-color">
  <defs>
    <clipPath id="clip-path-box">
      <rect id="Rectangle_397" data-name="Rectangle 397" width="25" height="25" fill="#006446"/>
    </clipPath>
  </defs>
  <path id="Path_1114" data-name="Path 1114" d="M21.5,17.5V7.5l-9-5-9,5v10l9,5Z" fill="#006446"/>
  <g id="Group_1035" data-name="Group 1035" >
    <g id="Group_1034" data-name="Group 1034" clip-path="url(#clip-path-box)">
      <path id="Path_1115" data-name="Path 1115" d="M3,1A2.006,2.006,0,0,0,1,3V22a2.005,2.005,0,0,0,2,2h18.99A2.006,2.006,0,0,0,24,22V3a2.007,2.007,0,0,0-2.006-2ZM3,0h18.99A3.014,3.014,0,0,1,25,3V22a3.012,3.012,0,0,1-3.006,3H3a3.013,3.013,0,0,1-3-3V3A3.015,3.015,0,0,1,3,0" fill="#006446"/>
      <rect id="Rectangle_396" data-name="Rectangle 396" width="2" height="9.665" transform="translate(11.5 9.739)" fill="#fff"/>
      <path id="Path_1116" data-name="Path 1116" d="M14,7.1a1.5,1.5,0,1,1-1.5-1.5A1.5,1.5,0,0,1,14,7.1" fill="#fff"/>
    </g>
  </g>
</svg>
<svg v-else id="info_box_hover" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
  <path id="Path_1114" data-name="Path 1114" d="M21.5,17.5V7.5l-9-5-9,5v10l9,5Z" fill="#006446"/>
  <g id="Group_1035" data-name="Group 1035">
    <g id="Group_1036" data-name="Group 1036">
      <rect id="Rectangle_398" data-name="Rectangle 398" width="25" height="25" rx="3" fill="#006446"/>
      <path id="Path_1115" data-name="Path 1115" d="M3,1A2.006,2.006,0,0,0,1,3V22a2.005,2.005,0,0,0,2,2h18.99A2.006,2.006,0,0,0,24,22V3a2.007,2.007,0,0,0-2.006-2ZM3,0h18.99A3.014,3.014,0,0,1,25,3V22a3.012,3.012,0,0,1-3.006,3H3a3.013,3.013,0,0,1-3-3V3A3.015,3.015,0,0,1,3,0" fill="#006446"/>
      <rect id="Rectangle_396" data-name="Rectangle 396" width="2" height="9.665" transform="translate(11.5 9.739)" fill="#76b62a"/>
      <path id="Path_1116" data-name="Path 1116" d="M14,7.1a1.5,1.5,0,1,1-1.5-1.5A1.5,1.5,0,0,1,14,7.1" fill="#76b62a"/>
    </g>
  </g>
</svg>

</div>

</template>
<style scoped>
.icon-color {
  stroke: currentColor
}
</style>
<script>

export default {
  data () {
    return {
      upHere: false
    }
  }
}
</script>
