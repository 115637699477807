<template>
  <v-app>
    <Loader v-if="loading"/>
    <v-fade-transition v-else mode="out-in">
      <NoConnection v-if="!isOnline"/>
      <router-view />
    </v-fade-transition>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/Loader'
import { CLEAR_TITLE_NOTIFICATION_INTERVAL } from '@/store/mutation-types'

export default {
  name: 'App',
  components: {
    NoConnection: () => import('@/components/NoConntection'),
    Loader
  },
  data () {
    return {
      isOnline: true
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading'
    })
  },
  mounted () {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    window.addEventListener('focus', this.stopTitleNotification)
  },
  beforeDestroy () {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
    window.removeEventListener('focus', this.stopTitleNotification)
  },
  methods: {
    updateOnlineStatus (e) {
      const { type } = e
      this.isOnline = type === 'online'
    },
    stopTitleNotification () {
      this.$store.commit(CLEAR_TITLE_NOTIFICATION_INTERVAL)
    }
  }
}
</script>
