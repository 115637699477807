<template>
  <div :class="classes" class="d-flex">
    <div class="pa-2 rounded text-wrap text-wrapper" :class="isCurrentUserSender ?  isDeleted ? 'ml-auto' : '' : 'ml-1'">
      <template v-if="isDeleted">
        <span class="font-italic">[{{ $t('chatWindow.messageDeleted') }}]</span>
        <div class="ml-1">
          <div v-for="(text, index) in splitedValue" :key="index">
            {{ text }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="align-center text-message">
          <div v-for="(text, index) in splitedValue" :key="index">
            {{ text }}
          </div>
        </div>
      </template>
    </div>
    <div v-if="!isDeleted" class="d-flex align-center" :class="isCurrentUserSender ? 'order-first ml-auto' : ''">
      <v-btn v-if="showMessageOptions" icon small @click="openMenu">
        <v-icon color="separator">uil uil-ellipsis-v</v-icon>
      </v-btn>
      <v-menu v-model="showMenu" absolute offset-y :position-x="menuPosition.x" :position-y="menuPosition.y" class="menu">
        <v-list class="py-0">
          <v-list-item v-if="canTranslate" dense @click="translateMessage" class="chat-menu">
            <v-icon small class="mr-2">$translate</v-icon>
            {{ $t('chatWindow.translateMessage') }}
          </v-list-item>
          <v-list-item v-if="canDelete" dense @click="deleteMessage" class="chat-menu">
            <v-icon small class="mr-2">$trash</v-icon>
            {{ $t('chatWindow.deleteMessage') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import Services from '@/services'
import { deleteMessage } from '@/signalr/chat.js'
export default {
  components: {},
  props: {
    value: Object,
    threadId: String,
    allowTranslate: Boolean,
    isArchive: Boolean,
    idToUseAsCurrentUser: String
  },
  data () {
    return {
      imageDialog: false,
      popupFile: null,
      showMenu: false,
      menuPosition: {
        x: 0,
        y: 0
      }
    }
  },
  computed: {
    isCurrentUserSender () {
      return this.currentUserId === this.value.sender
    },
    currentUserId () {
      if (this.idToUseAsCurrentUser) {
        return this.idToUseAsCurrentUser
      }
      return this.$store.getters.userCommonData.id
    },
    classes () {
      let classes = 'message'
      if (this.isCurrentUserSender) {
        classes += ' current-user-sender'
      }
      return classes
    },
    isTranslated () {
      return !!this.value.translation
    },
    isDeleted () {
      return this.value.deleted
    },
    canDelete () {
      return this.isCurrentUserSender && !this.isArchive
    },
    canTranslate () {
      return (
        !this.isCurrentUserSender && !this.value.translation && !this.isArchive
      )
    },
    canUndelete () {
      return this.isDeleted && this.isCustomerService && this.isArchive
    },
    showMessageOptions () {
      return this.canDelete || this.canTranslate || this.canUndelete
    },
    splitedValue () {
      const value = this.isTranslated ? this.value.translation : this.value.text
      console.log('split!')
      console.log(value.toString().split('\n'))
      return value.toString().split('\n')
    },
    isCustomerService () {
      return (
        this.$store.getters.isCustomerService || this.$store.getters.isAdmin
      )
    }
  },
  methods: {
    async translateMessage () {
      const response = await Services.get('ExternalApi/Translate', {
        text: this.value.text
      })
      this.$store.dispatch('chat/translateMessage', {
        id: this.value.id,
        text: response.data.data.translations[0].translatedText
      })
    },
    deleteMessage () {
      deleteMessage(this.threadId, this.value.id)
    },
    undeleteMessage () {},
    openMenu (e) {
      this.showMenu = true
      this.menuPosition.x = e.clientX
      this.menuPosition.y = e.clientY
    }
  }
}
</script>

<style scoped>
.text-message{
  word-break: break-word;
}
</style>
