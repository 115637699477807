<template>
  <div>
    <v-dialog v-model="dialog" width="800" scrollable>
      <v-card>
        <v-card-title>
          {{ $t('chatWindow.multimediaDialog.title') }}
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-row v-if="items.length <= 0">
            <v-col class="lighten-2 px-2 py-2 mx-1 my-1">
                  {{ $t("chatMenu.noFiles") }}
                </v-col>
          </v-row>
          <v-row v-for="(group, key) in monthGrouped" :key="group.key" dense>
            <v-col>
              <v-row dense>
                <v-col class="grey lighten-2 px-2 py-2 mx-1 my-1">
                  {{ key }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  cols="3"
                  v-for="item in group"
                  :key="item.originalName"
                  v-intersect.quiet="getFileThumbnail(item)"
                  class="text-center cursor-pointer mx-1 pa-0 rounded"
                  style="border: 1px solid grey"
                  @click="clickFile(item)"
                >
                  <div
                    v-if="item.type === 'IMAGE'"
                    class="d-flex flex-column w-100"
                  >
                    <v-img
                      class="w-100 h-100 rounded-b-0"
                      v-if="item.thumbnailUrl"
                      :src="item.thumbnailUrl"
                    ></v-img>
                    <span>{{ item.customName }}</span>
                  </div>
                  <div
                    v-else-if="item.type !== 'IMAGE'"
                    class="d-flex flex-column h-100 w-100 rounded-b-0"
                  >
                    <v-icon class="w-100 h-100" x-large>uil uil-file</v-icon>
                    <span>{{ item.customName }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title v-else>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-title>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <image-dialog ref="imageDialog" :threadId="threadId"></image-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import Services from '@/services'
import {
  groupBy,
  getFileTypeFromName,
  getFileExtensionFromName,
  downloadFile
} from '@/utils/helpers'
import ImageDialog from './ImageDialog'
export default {
  components: {
    ImageDialog
  },
  data () {
    return {
      dialog: false,
      items: [],
      loading: false
    }
  },
  props: {
    threadId: String
  },
  watch: {
    threadId (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.items = []
      }
    }
  },
  computed: {
    monthGrouped () {
      return groupBy(this.items, 'group')
    }
  },
  methods: {
    getFileTypeFromName,
    open () {
      this.dialog = true
      this.getItems()
    },
    close () {
      this.dialog = false
    },
    clickFile (file) {
      if (file.type === 'IMAGE') {
        this.$refs.imageDialog.open(file)
      } else this.download(file)
    },
    async download (file) {
      const response = await Services.getFile('Chat/GetChatFile', {
        threadId: this.threadId,
        fileName: file.originalName
      })
      downloadFile(
        { name: file.customName + '.' + file.extension },
        response.data
      )
    },
    async getFileThumbnail (item) {
      if (item.thumbnailUrl) {
        return
      }
      item.thumbnailUrl = await this.getFile(item.thumbnailName)
    },
    async getFile (name) {
      const { data } = await Services.getFile('Chat/GetChatFile', {
        threadId: this.threadId,
        fileName: name
      })
      const blob = new Blob([data])
      const fileUrl = URL.createObjectURL(blob)
      return fileUrl
    },
    async getItems () {
      this.loading = true
      try {
        const { data } = await Services.get('Chat/GetFileList', {
          threadId: this.threadId
        })

        const format = 'MMMM YYYY'

        data.forEach((item) => {
          if (!this.items.find((el) => el.originalName === item.originalName)) {
            this.items.push({
              ...item,
              thumbnailUrl: '',
              url: '',
              group: moment(item.uploadDateTime).format(format),
              type: getFileTypeFromName(item.originalName),
              extension: getFileExtensionFromName(item.originalName)
            })
          }
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
