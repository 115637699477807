export const getDefaultState = () => {
  return {
    drawer: (localStorage.getItem('autoStartChat') === 'true') ?? false,
    settings: {
      openWindowInside: false,
      newMessageOnlyOnList: false,
      openFromRightToLeft: false,
      pinToRight: false,
      newMessageSound: false,
      newOfferSound: false,
      acceptOfferSound: false,
      newOfferInFilterSound: false
    },
    notifications: 0,
    isUnpinedWindow: false,
    windows: [],
    reviewAdded: null,
    browserWidth: window.innerWidth,
    lastMessages: [],
    load: false,
    otherMessages: [],
    loadOther: false,
    helpMessages: [],
    loadHelp: false,
    offers: [],
    loadOffers: false,
    threadType: null,
    threads: [],
    loadThreads: false,
    order: null,
    newMarket: 0,
    newFreight: 0,
    newOther: 0,
    enable: true,
    autostart: (localStorage.getItem('autoStartChat') === 'true') ?? false,
    initUnPinedChat: false
  }
}

export default getDefaultState()
