import * as types from './mutation-types'
import router from '@/router'
import i18n from '@/i18n'
import { useFavicon } from '@vueuse/core'

export default {
  [types.LOADING] (state) {
    state.loading = false
  },
  [types.LOADED] (state) {
    state.loading = false
  },
  [types.LOGIN] (state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn
  },
  [types.ENUMS] (state, data) {
    state.enums = data
  },
  [types.SET_SNACKBAR] (state, snackBar) {
    state.snackBar = { ...state.snackBar, ...snackBar }
  },
  [types.SET_NOTIFICATION_DIALOG] (state, notificationDialog) {
    state.notificationDialog = {
      ...state.notificationDialog,
      ...notificationDialog
    }
  },
  [types.SET_USER_COMMON_DATA] (state, data) {
    state.userCommonData = data
  },
  [types.SET_LANGUAGES] (state, data) {
    state.languages = data
  },
  [types.SET_CURRENCIES] (state, data) {
    state.currencies = data
  },
  [types.SET_COUNTRIES] (state, data) {
    state.countries = data
  },
  [types.REFRESH_CONTACTS] (state, data) {
    state.contacts = data
  },
  [types.HELP] (state, data) {
    state.helpConversations = data
  },
  [types.CONTACT_CHANGE] (state, { userId, showCooperationState, cooperationState }) {
    const contact = state.contacts.contacts.find((user) => user.user.id === userId
    )
    if (contact) {
      contact.cooperationStatus = cooperationState
      contact.showCooperationStatus = showCooperationState
    }
    state.contacts.groups.forEach(element => {
      const user = element.users.find((el) => el.user.id === userId)
      if (user) {
        user.cooperationStatus = cooperationState
        user.showCooperationStatus = showCooperationState
      }
    })
  },
  [types.SET_TITLE_NOTIFICATION_INTERVAL] (state) {
    if (state.titleNotificationInterval) {
      return
    }
    const icon = useFavicon()
    icon.value = 'static/img/favicon_red.png'
    state.titleNotificationInterval = setInterval(() => {
      if (document.title === i18n.t('documentTitle.newMessage')) {
        document.title = router.currentRoute.name ? 'Spedimo - ' + i18n.t(`documentTitle.${router.currentRoute.name.toLowerCase()}`) || '' : 'Spedimo'
      } else {
        document.title = i18n.t('documentTitle.newMessage')
      }
    }, 2000)
  },
  [types.CLEAR_TITLE_NOTIFICATION_INTERVAL] (state) {
    const icon = useFavicon()
    icon.value = 'static/img/favicon.png'
    document.title = router.currentRoute.name ? 'Spedimo - ' + i18n.t(`documentTitle.${router.currentRoute.name.toLowerCase()}`) || '' : 'Spedimo'
    clearInterval(state.titleNotificationInterval)
    state.titleNotificationInterval = null
  }
}
