import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'

export default {
  close (window, data) {
    this.send(window, { type: 'close', ...data })
  },
  send (window, data) {
    window.postMessage(data, this.origin)
  },
  receiveChild (event) {
    if (event.origin !== this.origin) return
    if (!event.data.type) return
    if (event.data.type === 'close') {
      self.close()
    }
    if (event.data.type === 'noYouCantExist') {
      self.close()
    }
    if (event.data.type === 'yesYouCanExist') {
      store.dispatch('chat/openConversation', { conversationId: router.currentRoute.params.id, threadId: router.currentRoute.params.threadId ? router.currentRoute.params.threadId : null })
    }
    if (event.data.type === 'yesYouCanChangeThread') {
      store.dispatch('chat/closeConversation', { conversationId: event.data.id, currentThreadId: event.data.currentThreadId })
      store.dispatch('chat/openConversation', { conversationId: event.data.id, threadId: event.data.threadId })
      router.push({ name: 'UnpinedChat', params: { id: event.data.id, threadId: event.data.threadId } })
    }
    if (event.data.type === 'noYouCantChangeThread') {
      store.dispatch('setInfoNotification', i18n.t('chatWindow.thisThreadIsExist'), { root: true })
    }
  },
  initChild (window) {
    if (!window.opener) self.close()
    if (window.opener) this.send(window.opener, { type: 'canIExist', id: router.currentRoute.params.id, threadId: router.currentRoute.params.threadId ? router.currentRoute.params.threadId : null })
    window.addEventListener('message', this.receiveChild, false)
    window.addEventListener('beforeunload', () => this.destroyChild(window))
    window.addEventListener('blur', (event) => this.minimalizeWindow(router.currentRoute.params.id, router.currentRoute.params.threadId ? router.currentRoute.params.threadId : null, true))
    window.addEventListener('focus', (event) => this.minimalizeWindow(router.currentRoute.params.id, router.currentRoute.params.threadId ? router.currentRoute.params.threadId : null, false))
  },
  canIChangeThread (conversationId, currentThreadId, threadId) {
    window.opener.postMessage({ type: 'canIChangeThread', id: conversationId, currentThreadId, threadId }, this.origin)
  },
  destroyChild (window) {
    this.send(window.opener, { type: 'close', ...router.currentRoute.params })
    window.removeEventListener('message', this.receiveChild)
    window.removeEventListener('blur', (event) => this.minimalizeWindow(router.currentRoute.params.id, router.currentRoute.params.threadId ? router.currentRoute.params.threadId : null, true))
    window.removeEventListener('focus', (event) => this.minimalizeWindow(router.currentRoute.params.id, router.currentRoute.params.threadId ? router.currentRoute.params.threadId : null, false))
    window.removeEventListener('beforeunload', () => this.destroyChild(window))
  },
  receiveParent (event) {
    if (event.origin !== this.origin) return
    if (!event.data.type) return
    if (event.data.type === 'close') {
      store.dispatch('chat/closeConversation', { conversationId: event.data.id, currentThreadId: event.data.threadId })
    }
    if (event.data.type === 'canIExist') {
      const findWindow = store.getters['chat/windows'].find((el) => el.id === event.data.id && el.currentThreadId === event.data.threadId)
      if (findWindow) {
        event.source.postMessage({ type: 'yesYouCanExist' }, this.origin)
      } else {
        event.source.postMessage({ type: 'noYouCantExist' }, this.origin)
      }
    }
    if (event.data.type === 'canIChangeThread') {
      const findWindow = store.getters['chat/windows'].find((el) => el.id === event.data.id && el.currentThreadId === event.data.threadId)
      if (findWindow) {
        event.source.postMessage({ type: 'noYouCantChangeThread' }, this.origin)
      } else {
        const newWindow = store.getters['chat/windows'].find((window) => window.id === event.data.id && window.currentThreadId === event.data.currentThreadId)
        if (newWindow) {
          newWindow.currentThreadId = event.data.threadId
        }
        event.source.postMessage({ type: 'yesYouCanChangeThread', id: event.data.id, threadId: event.data.threadId, currentThreadId: event.data.currentThreadId }, this.origin)
      }
    }
  },
  initParent () {
    window.addEventListener('message', this.receiveParent, false)
    window.addEventListener('beforeunload', () => this.destroyParent)
  },
  destroyParent () {
    window.removeEventListener('message', this.receiveParent)
    window.removeEventListener('beforeunload', () => this.destroyParent)
  },
  minimalizeWindow (conversationId, currentThreadId, minimalize) {
    store.commit('chat/MINIMALIZE_WINDOW', {
      conversationId: conversationId,
      currentThreadId: currentThreadId,
      value: minimalize
    })
  }
}
