import {
  BLOCKEDCOOPERATION,
  LOCATIONCROSS,
  LOCATIONSTRAIGHT,
  MAX,
  MIN,
  MULTI,
  MYCOMPANY,
  NEGOTIATEDBYCOMPANY,
  ONLYMINE,
  SEARCH,
  CABOTAGE,
  DEALBYCOMPANY,
  DEALBYUSER,
  GROUP
} from '@/types/condition-types'
import { DATETIME, DECIMAL, INT, MEASUREDATETIME } from '@/types/filter-value-types'
import { generateString } from '@/utils/helpers'
import { FREIGHT, MY_FREIGHT, MY_VEHICLE, VEHICLE } from '@/types/order-types'
import i18n from '@/i18n'
import store from '@/store'
import * as moment from 'moment'

const stringIsNotEmpty = (value) => {
  return value && value.length > 0
}

const arrayIsNotEmpty = (value) => {
  return Array.isArray(value) && value.length > 0
}

const numberIsNotZero = (value) => {
  return value !== 0 && value > 0
}

const numberIsNotZeroAndInt = (value) => {
  return Number.isInteger(value) && value !== 0
}

const isLocationInFilters = (conditions) => {
  const locations = conditions?.find(c => c.loadingPlaces && c.unloadingPlaces)

  if (!locations) {
    return false
  }
  return true
}

const isSearchInFilters = (conditions) => {
  const search = conditions?.find(c => c.type === SEARCH)

  if (!search) {
    return false
  }
  return true
}

const locationIsNotEmpty = (value) => {
  if (arrayIsNotEmpty(value)) {
    for (let i = 0; i < value.length; i++) {
      if (!stringIsNotEmpty(value[i].country)) {
        return false
      }
    }
    return true
  } else {
    return false
  }
}

const checkPlace = (value) => {
  return value
    .filter(location => !!location.country)
    .map(location => {
      if ((!location.place || !location.place.city || location.place.city.length < 1) && (!location.place || !location.place.postalCode || location.place.postalCode.length < 1)) {
        location.place = null
      }
      return location
    })
}

export const convertFilters = (orderType, filters) => {
  const result = {
    type: orderType,
    name: stringIsNotEmpty(filters.name) ? filters.name : null,
    conditions: []
  }

  for (const filter in filters) {
    switch (filters[filter].type) {
      case NEGOTIATEDBYCOMPANY:
        if (stringIsNotEmpty(filters[filter].companyId)) {
          result.conditions.push(filters[filter])
        }
        break
      case DEALBYCOMPANY:
        if (stringIsNotEmpty(filters[filter].companyId)) {
          result.conditions.push(filters[filter])
        }
        break
      case DEALBYUSER:
        if (stringIsNotEmpty(filters[filter].userId)) {
          result.conditions.push(filters[filter])
        }
        break
      case SEARCH:
        if (stringIsNotEmpty(filters[filter].value)) {
          result.conditions.push(filters[filter])
        }
        break
      case MIN:
      case MAX:
        if (filters[filter].valueType === DECIMAL) {
          if (numberIsNotZero(filters[filter].value)) {
            result.conditions.push(filters[filter])
          }
        } else {
          if (numberIsNotZeroAndInt(filters[filter].value) || stringIsNotEmpty(filters[filter].value)) {
            result.conditions.push(filters[filter])
          }
        }
        break
      case LOCATIONSTRAIGHT:
      case LOCATIONCROSS:
        if (locationIsNotEmpty(filters[filter].loadingPlaces) || locationIsNotEmpty(filters[filter].unloadingPlaces)) {
          filters[filter].loadingPlaces = checkPlace(filters[filter].loadingPlaces)
          filters[filter].unloadingPlaces = checkPlace(filters[filter].unloadingPlaces)

          result.conditions.push(filters[filter])
        }
        break
      case MULTI:
        if (filters[filter].value !== null && filters[filter].value !== undefined) {
          if (arrayIsNotEmpty(filters[filter].value)) {
            result.conditions.push(filters[filter])
          } else if (!Array.isArray(filters[filter].value)) {
            result.conditions.push({
              ...filters[filter],
              value: [filters[filter].value]
            })
          }
        }
        break
      case GROUP:
      case ONLYMINE:
      case MYCOMPANY:
      case BLOCKEDCOOPERATION:
      case CABOTAGE:
        if (filters[filter].value) {
          result.conditions.push(filters[filter])
        }
        break
    }
  }

  return result
}

const createLocationName = (locations) => {
  const loadingPlaces = locations.loadingPlaces[0]
  const unloadingPlaces = locations.unloadingPlaces[0]
  const generateNameLocation = (location) => {
    let result = ''
    if (location && location.country && location.country.length > 0) {
      result = `${location.country}`
    }
    if (location && location.place && location.place.postalCode && location.place.postalCode.length > 0) {
      result = `${result}, ${location.place.postalCode}`
    }
    if (location && location.place && location.place.city && location.place.city.length > 0) {
      result = `${result}, ${location.place.city}`
    }
    return result
  }
  return `${generateNameLocation(loadingPlaces)} ${generateNameLocation(unloadingPlaces)}`
}

export const createFilterName = (filter) => {
  if (filter.isHardcoded) {
    return i18n.t((`market.${filter.hardcodedKey}`))
  }
  if (stringIsNotEmpty(filter.name)) {
    return filter.name
  }
  if (isLocationInFilters(filter.conditions)) {
    const locations = filter.conditions.find(c => c.loadingPlaces && c.unloadingPlaces)
    return createLocationName(locations)
  }
  if (isSearchInFilters(filter.conditions)) {
    const search = filter.conditions.find(c => c.type === SEARCH)
    return search.value
  }
  return i18n.t(('market.noName'))
}

export const convertDataToFilters = (filters) => {
  const result = {
    name: '',
    onlyMine: {
      type: ONLYMINE,
      value: false
    },
    group: {
      type: GROUP,
      value: null
    },
    myCompany: {
      type: MYCOMPANY,
      value: false
    },
    minHeight: {
      type: MIN,
      name: 'Height',
      valueType: DECIMAL,
      value: null
    },
    minLoad: {
      type: MIN,
      name: 'Load',
      valueType: DECIMAL,
      value: null
    },
    maxLoad: {
      type: MAX,
      name: 'Load',
      valueType: DECIMAL,
      value: null
    },
    minFreightWeight: {
      type: MIN,
      name: 'FreightWeight',
      valueType: DECIMAL,
      value: null
    },
    maxFreightWeight: {
      type: MAX,
      name: 'FreightWeight',
      valueType: DECIMAL,
      value: null
    },
    minStart: {
      type: MIN,
      name: 'Start',
      valueType: DATETIME,
      value: null
    },
    maxStart: {
      type: MAX,
      name: 'Start',
      valueType: DATETIME,
      value: null
    },
    minEnd: {
      type: MIN,
      name: 'End',
      valueType: DATETIME,
      value: null
    },
    maxEnd: {
      type: MAX,
      name: 'End',
      valueType: DATETIME,
      value: null
    },
    minLoadMeters: {
      type: MIN,
      name: 'LoadMeters',
      valueType: DECIMAL,
      value: null
    },
    maxLoadMeters: {
      type: MAX,
      name: 'LoadMeters',
      valueType: DECIMAL,
      value: null
    },
    minWidth: {
      type: MIN,
      name: 'Width',
      valueType: DECIMAL,
      value: null
    },
    minVolume: {
      type: MIN,
      name: 'Volume',
      valueType: DECIMAL,
      value: null
    },
    size: {
      type: MULTI,
      name: 'Size',
      value: null
    },
    loadForms: {
      type: MULTI,
      name: 'LoadForms',
      value: null
    },
    options: {
      type: MULTI,
      name: 'Options',
      value: null
    },
    type: {
      type: MULTI,
      name: 'Type',
      value: null
    },
    search: {
      type: SEARCH,
      value: null
    },
    locations: {
      type: LOCATIONCROSS,
      loadingPlaces: [
        { value: createNewPlace() }
      ],
      unloadingPlaces: [
        { value: createNewPlace() }
      ]
    },
    minPrice: {
      type: MIN,
      name: 'Price',
      valueType: DECIMAL,
      value: null
    },
    minPublishDate: {
      type: MIN,
      name: 'PublishDate',
      valueType: MEASUREDATETIME,
      value: null
    },
    maxPublishDate: {
      type: MAX,
      name: 'PublishDate',
      valueType: MEASUREDATETIME,
      value: null
    },
    maxPrice: {
      type: MAX,
      name: 'Price',
      valueType: DECIMAL,
      value: null
    },
    freightType: {
      type: MULTI,
      name: 'FreightType',
      value: null
    },
    currency: {
      type: MULTI,
      name: 'Currency',
      value: null
    },
    daysForPayment: {
      type: MIN,
      name: 'DaysForPayment',
      valueType: INT,
      value: null
    },
    vehicleFreightTypes: {
      type: MULTI,
      name: 'VehicleFreightTypes',
      value: null
    },
    companyPayerStatus: {
      type: MULTI,
      name: 'CompanyPayerStatus',
      value: null
    },
    companyRating: {
      type: MIN,
      name: 'CompanyRating',
      valueType: DECIMAL,
      value: null
    },
    negotiatedByCompany: {
      type: NEGOTIATEDBYCOMPANY,
      companyId: null
    },
    publisherId: {
      type: MULTI,
      name: 'PublisherId',
      value: null
    },
    paymentType: {
      type: MULTI,
      name: 'PaymentType',
      value: null
    },
    blockedCooperation: {
      type: BLOCKEDCOOPERATION,
      value: false
    },
    cabotage: {
      type: CABOTAGE,
      value: false
    },
    dealByCompany: {
      type: DEALBYCOMPANY,
      companyId: null
    },
    dealByUser: {
      type: DEALBYUSER,
      userId: null
    },
    companyId: {
      type: MULTI,
      name: 'CompanyId',
      value: null
    }
  }

  if (!filters) {
    return result
  }

  result.name = filters.newTab ? '' : createFilterName(filters)

  for (const filter in filters.conditions) {
    switch (filters.conditions[filter].type) {
      case SEARCH:
        if (stringIsNotEmpty(filters.conditions[filter].value)) {
          result.search.value = filters.conditions[filter].value
        }
        break
      case MIN:
        Object.keys(result).forEach(el => {
          if (result[el].type === MIN && result[el].name === filters.conditions[filter].name) {
            result[el].value = filters.conditions[filter].value
          }
        })
        break
      case MAX:
        Object.keys(result).forEach(el => {
          if (result[el].type === MAX && result[el].name === filters.conditions[filter].name) {
            result[el].value = filters.conditions[filter].value
          }
        })
        break
      case LOCATIONSTRAIGHT:
        if (locationIsNotEmpty(filters.conditions[filter].loadingPlaces) && locationIsNotEmpty(filters.conditions[filter].unloadingPlaces)) {
          result.locations = {
            ...filters.conditions[filter],
            loadingPlaces: filters.conditions[filter].loadingPlaces.map(i => ({ value: i })),
            unloadingPlaces: filters.conditions[filter].unloadingPlaces.map(i => ({ value: i }))
          }
        }
        break
      case LOCATIONCROSS:
        result.locations.type = filters.conditions[filter].type
        if (locationIsNotEmpty(filters.conditions[filter].loadingPlaces)) {
          result.locations = {
            ...result.locations,
            loadingPlaces: filters.conditions[filter].loadingPlaces.map(i => ({ value: i }))
          }
        } else {
          result.locations = {
            ...result.locations,
            loadingPlaces: [
              { value: createNewPlace() }
            ]
          }
        }

        if (locationIsNotEmpty(filters.conditions[filter].unloadingPlaces)) {
          result.locations = {
            ...result.locations,
            unloadingPlaces: filters.conditions[filter].unloadingPlaces.map(i => ({ value: i }))
          }
        } else {
          result.locations = {
            ...result.locations,
            unloadingPlaces: [
              { value: createNewPlace() }
            ]
          }
        }
        break
      case ONLYMINE:
        result.onlyMine.value = true
        break
      case DEALBYUSER:
        result.dealByUser.userId = filters.conditions[filter].userId
        break
      case MYCOMPANY:
        result.myCompany.value = true
        break
      case GROUP:
        result.group.value = filters.conditions[filter].value
        break
      case MULTI:
        Object.keys(result).forEach(el => {
          if (result[el].type === MULTI && result[el].name === filters.conditions[filter].name) {
            if (selectConditions.includes(filters.conditions[filter].name)) {
              result[el].value = filters.conditions[filter].value[0]
            } else {
              result[el].value = filters.conditions[filter].value
            }
          }
        })
        break
      case NEGOTIATEDBYCOMPANY:
        result.negotiatedByCompany.companyId = filters.conditions[filter].companyId
        break
      case DEALBYCOMPANY:
        result.dealByCompany.companyId = filters.conditions[filter].companyId
        break
      case BLOCKEDCOOPERATION:
        result.blockedCooperation.value = true
        break
      case CABOTAGE:
        result.cabotage.value = true
        break
    }
  }

  return result
}

const selectConditions = ['Currency', 'CompanyPayerStatus', 'PublisherId', 'PaymentType', 'State', 'CompanyId', 'VehicleFreightTypes']

export const newFilter = () => {
  return {
    newTab: true,
    id: generateString(),
    budge: 0,
    conditions: [],
    hardcodedKey: null,
    isHardcoded: false
  }
}

export const createUrlHub = (orderType) => {
  const lang = localStorage.getItem('lang') || 'pl-PL'

  switch (orderType) {
    case VEHICLE:
      return `${process.env.VUE_APP_BASE}vehicleHub?Language=${lang}`
    case FREIGHT:
      return `${process.env.VUE_APP_BASE}freightHub?Language=${lang}`
    case MY_VEHICLE:
      return `${process.env.VUE_APP_BASE}myVehicleHub?Language=${lang}`
    case MY_FREIGHT:
      return `${process.env.VUE_APP_BASE}myFreightHub?Language=${lang}`
    default:
      return null
  }
}

export const createNewPlace = (initDate = moment(), addDays = 0) => {
  return {
    id: generateString(),
    location: {
      country: null,
      place: {
        city: null,
        postalCode: null,
        latitude: 0,
        longitude: 0,
        range: null
      }
    },
    date: moment(initDate).add(addDays, 'days').format('YYYY-MM-DD'),
    enableHour: false,
    from: null,
    to: null,
    fixedTime: false
  }
}

export const catchMarketError = async (error, popup = false, action = () => {}) => {
  const response = error.response.data
  if (response.Status !== 400 || !response.ShouldBeShown) {
    console.error('catchMarketError', error)
    return
  }
  if (popup) {
    await store.dispatch('setErrorNotificationDialog', {
      message: response.Title,
      action
    })
  } else {
    await store.dispatch('setErrorNotification', response.Title)
  }
}
