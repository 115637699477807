<template>
  <div class="chat-thread" ref="chatThread">
    <div class="text-center">
      <v-progress-circular
        v-if="loading"
        :width="3"
        :size="25"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <message
      :isArchive="isArchive"
      :id="'message' + message.id"
      v-for="message in displayMessages"
      :key="message.id"
      :value="message"
      :ref="`message${message.id}`"
      v-intersect="{ handler: intersection }"
      :threadId="threadId"
      :minimalize="minimalize"
      :idToUseAsCurrentUser="idToUseAsCurrentUser"
    ></message>
  </div>
</template>

<script>
import moment from 'moment'
import Message from '../Messages/Message.vue'
import Services from '@/services'
export default {
  props: {
    conversation: Object,
    threadId: String,
    canSetAsRead: Boolean,
    isArchive: Boolean,
    idToUseAsCurrentUser: String,
    isOpen: Boolean
  },
  components: {
    Message
  },
  data () {
    return {
      isMoreEarlierMessages: true,
      loading: false,
      lastMessageId: 0
    }
  },
  watch: {
    isOpen: {
      handler (newVal) {
        this.scrollToEnd()
      }
    },
    messages: {
      handler (newVal) {
        if (!newVal) {
          return
        }
        var newLastElement = newVal[newVal?.length - 1]
        if (newLastElement?.id !== this.lastMessageId) {
          this.scrollToEnd()
        }
        this.lastMessageId = newLastElement?.id
      }
    }
  },
  computed: {
    minimalize () {
      return this.currentWindow?.minimalize ?? false
    },
    currentWindow () {
      return this.$store.getters['chat/windows'].find(
        (el) => el.id === this.conversation.id && el.currentThreadId === this.threadId
      )
    },
    firstMessageId () {
      return this.messages[0]?.id ?? 0
    },
    thread () {
      return this.conversation.threads.find((el) => el.id === this.threadId)
    },
    messages () {
      return this.thread?.messages
    },
    displayMessages () {
      return this.messages?.map((el, index, array) => {
        const nextEl = array[index + 1]
        this.$set(el, 'showAvatar', true) // TODO: remove showAvatar
        if (nextEl) {
          // if (nextEl.sender === el.sender) {
          //   this.$set(el, 'showAvatar', true)
          // } else {
          //   this.$set(el, 'showAvatar', true)
          // }

          if (index === 0) {
            this.$set(el, 'showDate', true)
          } else if (this.diffMinutes(nextEl.creationDate, el.creationDate) <= 1) {
            if (!(el.showDate === false || el.showDate === true)) {
              this.$set(el, 'showDate', false)
            }
            this.$set(nextEl, 'showDate', false)
          } else {
            this.$set(el, 'showDate', true)
            this.$set(nextEl, 'showDate', true)
          }
        } else {
          // this.$set(el, 'showAvatar', true)
          if (index === 0) {
            this.$set(el, 'showDate', true)
          }
        }

        return el
      })
    },
    currentUserId () {
      return this.$store.getters.userCommonData.id
    },
    anyUnreadedMessages () {
      return !!this.messages?.find(
        (message) =>
          !!message?.users?.find(
            (user) => user.id === this.currentUserId && !user.read
          )
      )
    }
  },
  methods: {
    diffMinutes (date, otherDate) {
      return moment(date).diff(moment(otherDate), 'minutes')
    },
    scrollToEnd () {
      this.$nextTick(() => {
        const el = this.$el
        setTimeout(function () {
          el.scrollTop = el.scrollHeight
        }, 1)
      })
    },
    async getMessages () {
      this.loading = true
      try {
        const response = await Services.get('Chat/LoadMessages', {
          loadNewer: false,
          threadId: this.threadId,
          messageId: this.firstMessageId
        })
        if (response.data.length > 0) {
          this.$store.commit('chat/ADD_MESSAGES', {
            conversationId: this.conversation.id,
            threadId: this.threadId,
            messages: response.data,
            asFirst: true
          })
        } else {
          this.isMoreEarlierMessages = false
        }
      } finally {
        this.loading = false
      }
    },
    async intersection (event) {
      if (event[0].isIntersecting && this.isMoreEarlierMessages) {
        const target = event[0].target
        const elementid = target.id
        const id = elementid.replace('message', '')
        if (id === this.messages[0].id) {
          await this.getMessages()
        }
      }
    },
    scrollTo (element) {
      element.scrollIntoView()
    }
  },
  async mounted () {
    this.scrollToEnd()
  }
}
</script>

<style>
.chat-thread {
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
