import store from '@/store'

export const getPermission = (key) => {
  const permissions = store.getters.permissions
  const permission = permissions.find(item => item.name === key)
  if (permission) {
    return permission.active
  } else {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`Permission '${key}' doesn't exist`)
    }
    return false
  }
}
