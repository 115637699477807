export const SET_LOADING = 'SET_LOADING'
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE'
export const SET_FILTER = 'SET_FILTER'
export const ADD_FILTER = 'ADD_FILTER'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB'
export const UPDATE_ITEMS = 'UPDATE_ITEMS'
export const CLEAR_ITEMS = 'CLEAR_ITEMS'
export const ADD_ITEM = 'ADD_ITEM'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const ADD_BUDGE = 'ADD_BUDGE'
export const CLEAR_BUDGE = 'CLEAR_BUDGE'
export const SET_BLANK_ITEMS = 'SET_BLANK_ITEMS'
export const RESET_STATE = 'RESET_STATE'
export const SET_SHOW_SCROLL_TO_TOP_BUTTON = 'SET_SHOW_SCROLL_TO_TOP_BUTTON'
export const MARK_SHOW_OFFER = 'MARK_SHOW_OFFER'
export const SET_EXPANDED_TAB = 'SET_EXPANDED_TAB'
