<template>
  <div :class="classes" class="d-flex">
    <div class="pa-2 rounded text-wrap text-wrapper" :class="isCurrentUserSender ? 'ml-auto' : 'ml-1'">
      <div class="d-flex align-center">
        <div class="rounded mr-2" style="border: solid thin var(--v-accent-base)">
          <v-icon size="22" color="accent">$deal</v-icon>
        </div>
        <div>
          {{ $t('chatWindow.deal.acceptedOffer') }}: {{ value.offer.price }} {{ currency }}
          <div class="body-2 accent--text">{{ value.creationDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    threadId: String,
    idToUseAsCurrentUser: String
  },
  data () {
    return {}
  },
  computed: {
    currency () {
      return this.allCurrency.find((el) => el.id === this.value.offer?.currency)
        ?.description
    },
    allCurrency () {
      return this.$store.getters.enums.currency
    },
    isCurrentUserSender () {
      return this.currentUserId === this.value.sender
    },
    currentUserId () {
      if (this.idToUseAsCurrentUser) {
        return this.idToUseAsCurrentUser
      }
      return this.$store.getters.userCommonData.id
    },
    classes () {
      let classes = 'message'
      if (this.isCurrentUserSender) {
        classes += ' current-user-sender'
      }
      return classes
    }
  },
  methods: {}
}
</script>
<style scoped>
.current-user-sender .text-wrapper {
  background-color: var(--v-primary-base);
  color: white;
}
</style>
