export const LOADING = 'LOADING'
export const LOADED = 'LOADED'
export const LOGIN = 'LOGIN'
export const ENUMS = 'ENUMS'
export const SET_SNACKBAR = 'SET_SNACKBAR'
export const SET_NOTIFICATION_DIALOG = 'SET_NOTIFICATION_DIALOG'
export const SET_USER_COMMON_DATA = 'SET_USER_COMMON_DATA'
export const SET_LANGUAGES = 'SET_LANGUAGES'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const REFRESH_CONTACTS = 'REFRESH_CONTACTS'
export const SET_CURRENCIES = 'SET_CURRENCIES'
export const HELP = 'HELP'
export const CONTACT_CHANGE = 'CONTACT_CHANGE'
export const SET_TITLE_NOTIFICATION_INTERVAL = 'SET_TITLE_NOTIFICATION_INTERVAL'
export const CLEAR_TITLE_NOTIFICATION_INTERVAL = 'CLEAR_TITLE_NOTIFICATION_INTERVAL'
