import Service from '@/services'
import * as types from './chat-mutation-types'
import * as signalr from '@/signalr/chat'
import router from '@/router'
import * as thread from '@/types/conversation-thread-type'
import * as list from '@/types/chat-list'
import i18n from '@/i18n'
import { generateString } from '@/utils/helpers'
import { NEW_DEAL_MESSAGE, NEW_MESSAGE } from '@/types/new-message-type'
import { HELPDESK_CHAT_COMPLETION, COUNTEROFFER, DEAL, OFFER } from '@/types/message-types'

export const fetchSettings = async ({ commit }) => {
  try {
    const { data } = await Service.get('Chat/GetSettings')
    commit(types.SET_SETTINGS, data)
  } catch (e) {}
}

export const notifications = async ({ commit }) => {
  try {
    const { data } = await Service.get('Chat/GetUnreadThreadsCount')
    commit(types.SET_NOTIFICATIONS, data.count)
    console.log('dispatch notifications')
  } catch (e) {}
}

export const openConversation = async ({ commit, state, dispatch, getters }, { conversationId, threadId }) => {
  if (!getters.enable) {
    return
  }
  console.log('openConversation')
  let window = state.windows.find((el) => el.id === conversationId && el.currentThreadId === threadId)
  if (!window) {
    const conversation = await Service.get(`Chat/GetConversation?conversationId=${conversationId}`)

    window = {
      chatWindowId: generateString(),
      id: conversationId,
      conversation: conversation.data,
      currentThreadId: threadId,
      unpined: false,
      minimalize: false,
      blockedSendMessage: false,
      hasNewMessage: false
    }

    if (!state.isUnpinedWindow) {
      if (!state.settings.openWindowInside) {
        const popupWindow = await dispatch('createWindow', {
          conversationId: conversationId,
          currentThreadId: threadId
        })
        window.ref = popupWindow
        window.unpined = true
      } else {
        window.unpined = false
      }
    }
    state.windows.unshift(window)
  } else {
    if (window.unpined) {
      if (window.ref) {
        window.ref.focus()
      } else {
        const popupWindow = await dispatch('createWindow', {
          conversationId: conversationId,
          currentThreadId: threadId
        })
        window.ref = popupWindow
      }
    } else {
      window.minimalize = false
      const indexOf = state.windows.indexOf(window)
      state.windows.splice(indexOf, 1)
      state.windows.unshift(window)
    }
    commit(types.READ_NEW_MESSAGE, { conversationId: conversationId, currentThreadId: threadId })
  }
}

export const newMessage = async (
  { commit, state, getters, rootGetters },
  { userId, message, conversationId, threadId }
) => {
  if (!getters.enable) return
  let window = state.windows.find((el) => el.id === conversationId && el.currentThreadId === threadId)

  if (!window && !state.isUnpinedWindow) {
    if (state.settings.newMessageOnlyOnList) return
    const response = await Service.get(`Chat/GetConversation?conversationId=${conversationId}`)

    window = {
      chatWindowId: generateString(),
      id: conversationId,
      conversation: response.data,
      unpined: false,
      currentThreadId: threadId,
      minimalize: true,
      blockedSendMessage: false,
      hasNewMessage: false
    }

    if (!state.settings.openWindowInside && !state.isUnpinedWindow) {
      window.unpined = true
    }

    if (state.isUnpinedWindow && router.currentRoute.name === 'UnpinedChat') {
      window.unpined = true
    }

    state.windows.push(window)
  }

  const windowIndex = state.windows.findIndex((el) => el.id === conversationId && el.currentThreadId === threadId)
  window.hasNewMessage = userId !== rootGetters.userCommonData.id && (window.unpined || window.minimalize || windowIndex + 1 > getters.maxChatWindows)

  window.newMessageType = message.type === DEAL || message.type === OFFER || message.type === COUNTEROFFER ? NEW_DEAL_MESSAGE : NEW_MESSAGE

  const thread = window.conversation.threads.find((el) => el.id === threadId)
  const findMessage = thread.messages.find((el) => el.id === message.id)
  if (!findMessage) {
    thread.messages.push(message)
  }

  if (message.type === HELPDESK_CHAT_COMPLETION) {
    commit(types.CLOSE_HELPDESK_THREAD, ({ conversationId, threadId }))
  }
}

export const closeConversation = ({ commit }, payload) => {
  commit(types.CLOSE_CONVERSATION, payload)
}

export const unpinWindow = async ({ dispatch, state }, { conversationId, currentThreadId }) => {
  console.log('unpinWindow')
  const existWindow = state.windows.find((el) => el.id === conversationId && el.currentThreadId === currentThreadId)

  if (existWindow) {
    existWindow.unpined = true
    existWindow.minimalize = false
    const popupWindow = await dispatch('createWindow', {
      conversationId: conversationId,
      currentThreadId: currentThreadId
    })
    existWindow.ref = popupWindow
  }
}

export const createWindow = async ({ commit }, { conversationId, currentThreadId }) => {
  let url = `/unpined-chat/${conversationId}`
  if (currentThreadId) {
    url += `/${currentThreadId}`
  }
  const popupWindow = window.open(url, `${conversationId} - ${generateString()}`, 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=340px,height=580px,left=50px,top=50px')

  return popupWindow
}

export const focusPopupWindow = ({ commit, state }, { conversationId, currentThreadId }) => {
  const window = state.windows.find(w => w.id === conversationId && w.currentThreadId === currentThreadId)
  if (window) {
    if (window.ref) window.ref.focus()
    commit(types.READ_NEW_MESSAGE, { conversationId: conversationId, currentThreadId: currentThreadId })
  } else {
    commit(types.CLOSE_CONVERSATION, { conversationId: conversationId, currentThreadId: currentThreadId })
  }
}

export const clearConversations = ({ commit }) => {
  commit(types.CLEAR_CONVERSATIONS)
}

export const markAsRead = async ({ commit }, { userId, threadId, messageId }) => {
  commit(types.MARK_AS_READ, { userId, threadId, messageId })
  commit(types.READ_LAST_MESSAGE, messageId)
}

export const archiveThread = async (
  { state, commit, dispatch },
  { threadId }
) => {
  const window = state.windows.find((window) => window.conversation.threads.find((thread) => thread.id))
  if (window.currentThreadId === threadId) {
    dispatch('setSuccessNotification', i18n.t(('toasts.offerAvailable')), { root: true })
    window.currentThreadId = null
  }

  commit(types.ARCHIVE_THREAD, threadId)
}

export const changeCurrentThreadInWindow = ({ state, dispatch }, { conversationId, currentThreadId, threadId }) => {
  const existNewThreadWindow = state.windows.find((window) => window.id === conversationId && window.currentThreadId === threadId)
  if (existNewThreadWindow) {
    dispatch('setInfoNotification', i18n.t('chatWindow.thisThreadIsExist'), { root: true })
    return
  }
  const window = state.windows.find((window) => window.id === conversationId && window.currentThreadId === currentThreadId)
  if (window) {
    window.currentThreadId = threadId
    if (router.currentRoute.name === 'UnpinedChat') {
      router.push({ name: 'UnpinedChat', params: { id: router.currentRoute.params.id, threadId } })
    }
  }
}

export const addUserToConversation = ({ commit }, { conversationId, user }) => {
  commit(types.ADD_USER_TO_CONVERSATION, { conversationId, user })
}

export const reviewAdded = async ({ state, dispatch, commit, getters }, { orderId }) => {
  for (var i = 0; i < state.windows.length; i++) {
    const thread = state.windows[i].conversation.threads.find(
      (thread) => thread.associatedItem === orderId
    )
    if (!thread) {
      console.log('wracam')
      return
    }
    const { data } = await Service.get(
      `Chat/GetConversation?conversationId=${state.windows[i].conversation.id}`
    )
    var threadId = thread.id
    commit(types.REFRESH_CONVERSATION, { conversation: data, threadId })
    commit(types.REVIEW_ADDED, orderId)
  }
}

export const contactChange = (
  { commit },
  { userId, showCooperationState, cooperationState }
) => {
  commit(
    'CONTACT_CHANGE',
    { userId, showCooperationState, cooperationState },
    { root: true }
  )
}

export const getLastMessages = async ({ state, dispatch, commit, getters }) => {
  // let skipCount = 0

  // if (getters.load) {
  //   skipCount = getters.lastMessages.length
  // }
  if (!getters.load) {
    return
  }
  state.load = false

  try {
    const { data } = await Service.get('/Chat/GetLastThreads', {
      skipCount: getters.lastMessages.length
    })
    dispatch('notifications')
    commit(types.THREAD_TYPE, null)
    commit(types.LAST, data.results)
  } catch (e) {}
}

export const getOtherMessages = async ({ commit, getters }) => {
  let skipCount = 0

  if (getters.loadOther) {
    skipCount = getters.otherMessages.length
  }

  try {
    const { data } = await Service.get('/Chat/GetOtherThreads', {
      threadType: thread.Others,
      skipCount: skipCount
    })

    commit(types.THREAD_TYPE, thread.Others)
    commit(types.OTHER, data.results)
  } catch (e) {}
}

export const getHelpMessages = async ({ commit, getters }) => {
  let skipCount = 0

  if (getters.loadHelp) {
    skipCount = getters.helpMessages.length
  }

  try {
    const { data } = await Service.get('/Chat/GetOtherThreads', {
      threadType: thread.OthersHelpdesk,
      skipCount: skipCount
    })

    commit(types.THREAD_TYPE, thread.OthersHelpdesk)
    commit(types.HELP, data.results)
  } catch (e) {}
}

export const getOffers = async ({ commit, getters }, threadType) => {
  let skipCount = 0

  if (getters.loadOffers) {
    skipCount = getters.offers.length
  }

  try {
    const { data } = await Service.get('/Chat/GetOfferThreads', {
      threadType: threadType,
      skipCount: skipCount
    })

    commit(types.THREAD_TYPE, threadType)
    commit(types.OFFERS, data.results)
  } catch (e) {}
}

export const getOfferThreads = async ({ commit, getters }, orderId) => {
  let skipCount = 0

  if (getters.loadThreads) {
    skipCount = getters.threads.length
  }

  try {
    const { data } = await Service.get('/Chat/GetThreadsForMyOffer', {
      orderId: orderId,
      skipCount: skipCount
    })

    commit(types.ORDER, orderId)
    commit(types.THREADS, data.results)
  } catch (e) {}
}

export const updateList = async (
  { commit, state },
  { listName, conversationId, lastMsg, threadId }
) => {
  const last = state[listName].findIndex(
    (el) => el.conversationId === conversationId && el.threadId === threadId
  )
  if (last !== -1) {
    // commit(types.REMOVE_LAST, { conversationId, listName, threadId })
    state[listName].splice(last, 1)
  }
  commit(types.ADD_LAST, { lastMsg, listName })
}

export const updateMarket = async (
  { commit, state },
  { listName, orderId }
) => {
  const { data } = await Service.get(
    `/Chat/GetOfferThreadByOfferId?orderId=${orderId}`
  )

  const last = state[listName].find((el) => el.orderId === orderId)
  if (last) {
    commit(types.REMOVE_LAST_OFFER, { orderId, listName })
  }
  commit(types.ADD_LAST, { lastMsg: data, listName })
}

export const deleteMsgSidebar = async ({ state, getters }, { messageId }) => {
  let listName = null

  switch (getters.threadType) {
    case thread.Others:
      listName = list.OTHER
      break
    case thread.VehicleOrders:
    case thread.FreightOrders:
    case thread.MyVehicleOrders:
    case thread.MyFreightOrders:
      listName = list.THREADS
      break
    case thread.OthersHelpdesk:
      listName = list.HELP
      break
    default:
      listName = list.LAST
      break
  }

  const msg = state[listName].find(
    (el) => el.messageId === messageId
  )

  if (msg) msg.isMessageDeleted = true
}

export const message = async (
  { state, dispatch, getters, commit },
  { threadType, orderId, threadId, currentUserId, conversationId }
) => {
  const { data } = await Service.get(`/Chat/GetThreadById?threadId=${threadId}`)

  // if conversation is open
  if (currentUserId !== data.senderId) {
    const main = getters.innerWindows.slice(0, getters.maxChatWindows)
    const find = main.find(el => el.currentThreadId === data.threadId)
    if (find) {
      data.readByCurrentUser = !find.minimalize
      if (find.minimalize) {
        state.notifications++
      }
    } else {
      state.notifications++
    }
  }

  await dispatch('updateList', {
    listName: list.LAST,
    conversationId,
    lastMsg: data,
    threadId
  })

  if (getters.threadType !== data.threadType) {
    if (data.senderId !== currentUserId) commit(types.NEW, threadType)
    return
  }

  let listName = null

  switch (data.threadType) {
    case thread.Others:
      listName = list.OTHER
      break
    case thread.VehicleOrders:
    case thread.FreightOrders:
    case thread.MyVehicleOrders:
    case thread.MyFreightOrders:
      listName = list.MARKET
      break
    case thread.OthersHelpdesk:
      listName = list.HELP
      break
    default:
      listName = null
      break
  }

  if (!listName) return

  if (threadType === 1 || threadType === 2) {
    await dispatch('updateMarket', { listName, orderId })

    if (getters.order !== orderId) return

    await dispatch('updateList', {
      listName: 'threads',
      conversationId,
      lastMsg: data,
      threadId
    })
  } else {
    await dispatch('updateList', { listName, conversationId, lastMsg: data, threadId })
  }
}

export const translateMessage = ({ commit }, { id, text }) => {
  commit(types.TRANSLATE_MESSAGE, { id, text })
}

export const refreshConversation = async ({ commit }, { conversationId, threadId }) => {
  const { data } = await Service.get(
    `Chat/GetConversation?conversationId=${conversationId}`
  )
  commit(types.REFRESH_CONVERSATION, { conversation: data, threadId })
}

export const blockUser = ({ commit }, { userId, conversationId }) => {
  commit(types.BLOCK_USER, { userId, conversationId })
}

export const unblockUser = ({ commit }, { userId, conversationId }) => {
  commit(types.UNBLOCK_USER, { userId, conversationId })
}

export const deleteMessage = async ({ commit }, { threadId, messageId }) => {
  commit(types.DELETE_MESSAGE, { threadId, messageId })
}

export const enableChat = async ({ commit }) => {
  commit(types.ENABLE)
  await signalr.start()
}

export const disableChat = async ({ commit, dispatch }) => {
  commit(types.DISABLE)
  signalr.stop()
  dispatch('clearConversations')
}

export const markAsReceived = ({ commit }, { userId, threadId, messageId }) => {
  commit(types.MARK_AS_RECEIVED, { userId, threadId, messageId })
}

export const resetState = async ({ commit }) => {
  commit(types.RESET_STATE)
}

export const userStatusChanged = ({ commit }, { userId, userStatusType }) => {
  commit(types.USER_STATUS_CHANGED, { userId, userStatusType })
}
