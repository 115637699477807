import * as types from './chat-mutation-types'
import * as list from '@/types/chat-list'
import * as thread from '@/types/thread-type'
import { getDefaultState } from './chat-state'
import Vue from 'vue'
import * as conversationThreadTypes from '@/types/conversation-thread-type'
import { NEW_MESSAGE } from '@/types/new-message-type'
import { log } from '@/utils/logger'
import Messenger from '@/utils/messenger.js'

export default {
  [types.SET_DRAWER] (state, drawer) {
    state.drawer = drawer
  },
  [types.SET_SETTINGS] (state, settings) {
    state.settings = settings
  },
  [types.SET_NOTIFICATIONS] (state, notifications) {
    state.notifications = notifications
  },
  [types.READ_MSG] (state) {
    if (state.notifications > 0) state.notifications--
    console.log('READ_MSG')
  },
  [types.CLOSE_HELPDESK_THREAD] (state, { conversationId, threadId }) {
    const window = state.windows.find((el) => el.id === conversationId && el.currentThreadId === threadId)
    const conversation = window.conversation
    const thread = conversation.threads.find((el) => el.id === threadId)
    thread.showHelpdeskChatRate = true
    log('CLOSE_HELPDESK_THREAD')
  },
  [types.SET_BROWSER_WIDTH] (state, width) {
    state.browserWidth = width
  },
  [types.MINIMALIZE_WINDOW] (state, { conversationId, currentThreadId, value }) {
    const window = state.windows.find((el) => el.id === conversationId && el.currentThreadId === currentThreadId)
    if (window) {
      window.minimalize = value
      log('MINIMALIZE_WINDOW', JSON.stringify(window.minimalize))
    }
  },
  [types.READ_NEW_MESSAGE] (state, { conversationId, currentThreadId }) {
    const window = state.windows.find((el) => el.id === conversationId && el.currentThreadId === currentThreadId)
    if (window) {
      window.hasNewMessage = false
      window.newMessageType = NEW_MESSAGE
      log('READ_NEW_MESSAGE')
    }
  },
  [types.READ_LAST_MESSAGE] (state, messageId) {
    const msg = state.lastMessages.find((el) => el.messageId === messageId)
    if (msg) {
      if (!msg.readByCurrentUser) this.commit(`chat/${types.READ_MSG}`)
      msg.readByCurrentUser = true
      if (msg.threadType === state.threadType) {
        if (msg.threadType === conversationThreadTypes.VehicleOrders || msg.threadType === conversationThreadTypes.FreightOrders) {
          const offer = state.offers.find((el) => el.messageId === messageId)
          if (offer) offer.readByCurrentUser = true
        }
        if (msg.threadType === conversationThreadTypes.MyVehicleOrders || msg.threadType === conversationThreadTypes.MyFreightOrders) {
          const thread = state.threads.find((el) => el.messageId === messageId)
          if (thread) thread.readByCurrentUser = true
        }
      }
    }
  },
  [types.CLOSE_CONVERSATION] (state, { conversationId, currentThreadId }) {
    const window = state.windows.find((el) => el.id === conversationId && el.currentThreadId === currentThreadId)
    if (!window) {
      return
    }
    log('CLOSE_CONVERSATION', { conversationId, currentThreadId })
    const indexOf = state.windows.indexOf(window)
    state.windows.splice(indexOf, 1)
  },
  [types.SET_UNPINED_WINDOW] (state) {
    state.isUnpinedWindow = true
  },
  [types.ADD_MESSAGES] (state, { conversationId, threadId, messages, asFirst }) {
    const window = state.windows.find((el) => el.id === conversationId && el.currentThreadId === threadId)
    if (!window) {
      return
    }
    const thread = window.conversation.threads.find((el) => el.id === threadId)
    if (!thread) {
      return
    }
    if (asFirst) {
      thread.messages.unshift(...messages)
    } else {
      thread.messages.push(...messages)
    }
    log('ADD_MESSAGES')
  },
  [types.CLEAR_CONVERSATIONS] (state) {
    state.windows.forEach((window) => {
      if (window.ref) Messenger.close(window.ref)
    })
    state.windows = []
    log('CLEAR_CONVERSATIONS')
  },
  [types.LAST] (state, data) {
    if (data.length > 0) {
      data.forEach((item) => state.lastMessages.push(item))
      state.load = true
    } else {
      state.load = false
    }
  },
  [types.CLEAR_LAST] (state, listName) {
    state[listName] = []
    state.load = true
  },
  [types.REMOVE_LAST] (state, { conversationId, listName, threadId }) {
    state[listName] = state[listName].filter(
      (item) => item.conversationId !== conversationId || item.threadId !== threadId
    )
  },
  [types.REMOVE_LAST_OFFER] (state, { orderId, listName }) {
    state[listName] = state[listName].filter((item) => item.orderId !== orderId)
  },
  [types.ADD_LAST] (state, { lastMsg, listName }) {
    state[listName].unshift(lastMsg)
  },
  [types.OTHER] (state, data) {
    if (data.length > 0) {
      data.forEach((item) => state.otherMessages.push(item))
      state.loadOther = true
    } else {
      state.loadOther = false
    }
  },
  [types.CLEAR_OTHER] (state) {
    state.otherMessages = []
    state.loadOther = false
  },
  [types.HELP] (state, data) {
    if (data.length > 0) {
      data.forEach((item) => state.helpMessages.push(item))
      state.loadHelp = true
    } else {
      state.loadHelp = false
    }
  },
  [types.CLEAR_HELP] (state) {
    state.helpMessages = []
    state.loadHelp = false
  },
  [types.OFFERS] (state, data) {
    if (data.length > 0) {
      data.forEach((item) => state.offers.push(item))
      state.loadOffers = true
    } else {
      state.loadOffers = false
    }
  },
  [types.THREAD_TYPE] (state, data) {
    state.threadType = data
  },
  [types.ORDER] (state, data) {
    state.order = data
  },
  [types.CLEAR_OFFERS] (state) {
    state.offers = []
    state.loadOffers = false
    state.threadType = null
  },
  [types.THREADS] (state, data) {
    if (data.length > 0) {
      data.forEach((item) => state.threads.push(item))
      state.loadThreads = true
    } else {
      state.loadThreads = false
    }
  },
  [types.CLEAR_THREADS] (state) {
    state.threads = []
    state.loadThreads = false
    state.order = null
  },
  [types.NEW] (state, threadType) {
    switch (threadType) {
      case thread.VehicleOrders:
        state[list.newMarket] += 1
        break
      case thread.FreightOrders:
        state[list.newFreight] += 1
        break
      case thread.Others:
        state[list.newOther] += 1
        break
      default:
        break
    }
  },
  [types.CLEAR_NEW] (state, listName) {
    state[listName] = 0
  },
  [types.MARK_AS_READ] (state, { userId, threadId, messageId }) {
    state.windows.forEach((window) => {
      const thread = window.conversation.threads.find(
        (thread) => thread.id === threadId
      )
      if (!thread) {
        return
      }
      const message = thread.messages.find((el) => el.id === messageId)
      if (!message) {
        return
      }
      let user = message.users?.find((user) => user.id === userId)
      if (!user) {
        user = {
          id: userId,
          read: false,
          received: false
        }
        Vue.set(message.users, 'user', [])
        message.users.push(user)
      }

      Vue.set(user, 'read', true)
      Vue.set(user, 'received', true)

      this.commit(`chat/${types.READ_NEW_MESSAGE}`, { conversationId: window.conversation.id, currentThreadId: window.currentThreadId })
    })
    log('MARK_AS_READ')
  },
  [types.REVIEW_ADDED] (state, orderId) {
    state.reviewAdded = orderId
    log('REVIEW_ADDED')
  },
  [types.ARCHIVE_THREAD] (state, threadId) {
    state.windows.forEach((window) => {
      const thread = window.conversation.threads.find(
        (thread) => thread.id === threadId
      )
      if (thread) {
        const indexOf = window.conversation.threads.indexOf(thread)
        window.conversation.threads.splice(indexOf, 1)
      }
    })
    log('ARCHIVE_THREAD')
  },
  [types.TRANSLATE_MESSAGE] (state, { id, text }) {
    log(id, text)
    state.windows.forEach((window) => {
      window.conversation.threads.forEach((thread) => {
        const message = thread.messages.find((el) => el.id === id)
        if (message) {
          Vue.set(message, 'translation', text)
        }
      })
    })
    log('TRANSLATE_MESSAGE')
  },
  [types.ADD_USER_TO_CONVERSATION] (state, { conversationId, user }) {
    const window = state.windows.find((window) => window.id === conversationId)
    if (!window) {
      return
    }

    const userExit = !!window.conversation.users.find(
      (el) => el.user.id === user.user.id
    )
    if (userExit) {
      return
    }

    window.conversation.users.push(user)
    log('ADD_USER_TO_CONVERSATION')
  },
  [types.REFRESH_CONVERSATION] (state, { conversation, threadId }) {
    const window = state.windows.find((window) => window.id === conversation.id && window.currentThreadId === threadId)
    window.conversation = conversation
    log('REFRESH_CONVERSATION')
  },
  [types.BLOCK_USER] (state, { userId, conversationId }) {
    const windows = state.windows
      .filter((window) => window.conversation.id === conversationId)

    windows.forEach(window => {
      window.conversation.blockedBy = userId
    })
    log('BLOCK_USER')
  },
  [types.UNBLOCK_USER] (state, { userId, conversationId }) {
    const windows = state.windows
      .filter((window) => window.conversation.id === conversationId)

    windows.forEach(window => {
      window.conversation.blockedBy = null
    })
    log('UNBLOCK_USER')
  },
  [types.DELETE_MESSAGE] (state, { threadId, messageId }) {
    state.windows.forEach((window) => {
      const thread = window.conversation.threads.find(
        (thread) => thread.id === threadId
      )
      if (!thread) {
        return
      }
      const message = thread.messages.find(
        (message) => message.id === messageId
      )
      if (!message) {
        return
      }
      message.deleted = true
      message.text = ''
      message.files = []
      if (message.translation) {
        message.translation = ''
      }
    })
    log('DELETE_MESSAGE')
  },
  [types.ENABLE] (state) {
    state.enable = true
  },
  [types.DISABLE] (state) {
    state.enable = false
  },
  [types.MARK_AS_RECEIVED] (state, { userId, threadId, messageId }) {
    state.windows.forEach((window) => {
      const thread = window.conversation.threads.find(
        (thread) => thread.id === threadId
      )
      if (!thread) {
        return
      }
      const message = thread.messages.find(
        (message) => message.id === messageId
      )

      if (!message) {
        return
      }
      if (message) {
        let user = message.users?.find((user) => user.id === userId)

        if (!user) {
          user = {
            id: userId,
            read: false,
            received: false
          }
          Vue.set(message.users, 'user', [])
          message.users.push(user)
        }

        Vue.set(user, 'received', true)
      }
    })
    log('MARK_AS_RECEIVED')
  },
  [types.RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [types.UPDATE_BLOCKED_SEND_MESSAGE] (state, { conversationId, currentThreadId, blockedSendMessage }) {
    const window = state.windows.find((el) => el.id === conversationId && el.currentThreadId === currentThreadId)
    if (window) {
      window.blockedSendMessage = blockedSendMessage
      log('UPDATE_BLOCKED_SEND_MESSAGE', blockedSendMessage)
    }
  },
  [types.USER_STATUS_CHANGED] (state, { userId, userStatusType }) {
    state.windows.forEach(w => {
      const user = w.conversation?.users.find(
        (el) => el.user.id === userId
      )
      user.user.status = userStatusType
    })
  }
}
