<template>
  <div :class="classes" class="d-flex">
    <div class="pa-2 rounded text-wrap text-wrapper pointer" :class="isCurrentUserSender ? 'ml-auto localization' : 'ml-1'" @click="linkToMaps">
      <v-icon class="mr-1" :color="isCurrentUserSender ? 'primary' : 'white'" small >uil uil-location-point</v-icon>{{ $t('chatWindow.location') }}
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: Object,
    threadId: String,
    idToUseAsCurrentUser: String
  },
  computed: {
    isCurrentUserSender () {
      return this.currentUserId === this.value.sender
    },
    currentUserId () {
      if (this.idToUseAsCurrentUser) {
        return this.idToUseAsCurrentUser
      }
      return this.$store.getters.userCommonData.id
    },
    classes () {
      let classes = 'message'
      if (this.isCurrentUserSender) {
        classes += ' current-user-sender'
      }
      return classes
    }
  },
  methods: {
    linkToMaps () {
      window.open(
        `http://www.google.com/maps/place/${this.value.point.latitude},${this.value.point.longitude}`,
        '_blank'
      )
    }
  }
}
</script>

<style scoped>
.current-user-sender .text-wrapper {
  color: var(--v-primary-base);
}
.localization {
  border: solid thin var(--v-primary-base);
}
</style>
