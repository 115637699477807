<template>
  <div class="pa-4 shadow-top">
    <h4 class="body-1">{{ $t('chatWindow.rateHelpdesk') }}</h4>
    <div v-if="review">
      <label class="body-2 text-center">
        {{ $t('chatWindow.deal.review.ratingIssued') }}
      </label>
      <v-rating v-model="review.rate" length="5" hover color="accent" readonly />
    </div>
    <div v-else>
      <label class="body-2 text-center">
        {{ $t('chatWindow.deal.review.rate') }}
      </label>
      <v-rating v-model="rate" length="5" hover color="accent" />
      <v-btn color="primary" :loading="loading" class="mt-2" :disabled="loading || !rate" @click="sendReview" depressed>
        {{ $t('chatWindow.deal.review.addRating') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import Services from '@/services'
export default {
  props: {
    currentThreadId: String
  },
  data () {
    return {
      loading: false,
      review: null,
      rate: null
    }
  },
  methods: {
    async getReview () {
      try {
        this.loading = true
        const { data } = await Services.get('HelpdeskReview/Get', {
          threadId: this.currentThreadId
        })
        if (data) {
          this.review = data
        } else {
          this.review = null
        }
      } finally {
        this.loading = false
      }
    },
    async sendReview () {
      try {
        this.loading = true
        await Services.post('HelpdeskReview/Add', {
          threadId: this.currentThreadId,
          rate: this.rate
        })
        await this.getReview()
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    await this.getReview()
  }
}
</script>

<style></style>
