<template>
<div>
  <v-divider/>
  <v-row class="my-2" no-gutters>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.weightInTones') }}:</h4>
      <p>
        {{ details.weight }}
      </p>
    </v-col>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.freightCategory') }}:</h4>
      <p>
        {{ freightCategory}}
      </p>
    </v-col>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.proposedPrice') }}:</h4>
      <p>
        {{ details.price }} {{ offerCurrency }}
      </p>
    </v-col>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.loadMeters') }}:</h4>
      <p>
        {{ details.loadMeters }}
      </p>
    </v-col>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.freightType') }}:</h4>
      <p>
        {{ details.freightType }}
      </p>
    </v-col>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.datePayment') }}:</h4>
      <p>
        {{ details.daysForPayment }}
      </p>
    </v-col>
    <v-col cols="4">
      <h4 class="primary--text">{{ $t('offerDetails.volume') }}:</h4>
      <p>
        {{ details.volume }}
      </p>
    </v-col>
  </v-row>
  <v-divider/>
  <v-row class="my-2" no-gutters>
    <v-col cols="7">
      <h4 class="primary--text">{{ $t('offerDetails.description') }}:</h4>
      <p>
        {{ details.additionalDescription }}
      </p>
    </v-col>
    <v-col cols="5">
      <h4 class="primary--text">{{ $t('offerDetails.contactPerson') }}:</h4>
      <p>
        <span>{{ details.publisher | trimText(25) }}</span>
        <v-icon v-if="displayMessageAction" icon @click.stop="startConversationWithUser(details.publisherId)" color="separator" size="18" class="ml-2 mb-4">$conversation</v-icon>
      </p>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { startConversation } from '@/signalr/chat'
import { getEnumDescription } from '@/utils/helpers'

export default {
  name: 'FreightDetailsPanel',
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  computed: {
    displayMessageAction () {
      return this.$store.getters.userCommonData.id !== this.details.publisherId
    },
    allCurrencies () {
      return this.$store.getters.allCurrencies
    },
    offerCurrency () {
      return getEnumDescription(this.allCurrencies, this.details.currency)
    },
    freightTypes () {
      return this.$store.getters.enums.freightTypes
    },
    freightCategory () {
      return this.details.vehicleFreightTypes
        .map(t => (getEnumDescription(this.freightTypes, t)))
        .join(', ')
    }
  },
  methods: {
    startConversationWithUser (userId) {
      startConversation(userId)
    }
  }
}
</script>
