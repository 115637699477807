import {
  adminRoleId,
  customerServiceRoleId,
  ownerRoleId,
  workerRoleId,
  managerRoleId, driverRoleId
} from '@/utils/helpers'

export const loading = (state) => {
  return state.loading
}

export const isLoggedIn = (state) => {
  return state.isLoggedIn
}

export const enums = (state) => {
  return state.enums
}

export const permissions = (state) => {
  return state.userCommonData.permissions || []
}

export const role = (state) => {
  return state.userCommonData.role || 0
}

export const isAdmin = (state) => {
  return state.userCommonData.role === adminRoleId || false
}

export const isOwner = (state) => {
  return state.userCommonData.role === ownerRoleId || false
}

export const isWorker = (state) => {
  return state.userCommonData.role === workerRoleId || false
}

export const isDriver = (state) => {
  return state.userCommonData.role === driverRoleId || false
}

export const isManager = (state) => {
  return state.userCommonData.role === managerRoleId || false
}

export const isManagerOrOwner = (state) => {
  return state.userCommonData.role === managerRoleId || state.userCommonData.role === ownerRoleId || false
}

export const isMarketUser = (state) => {
  return isOwner(state) || isWorker(state) || isManager(state)
}

export const isCustomerService = (state) => {
  return state.userCommonData.role === customerServiceRoleId || false
}

export const isOwnerOrWorker = (state) => {
  return isOwner(state) || isWorker(state)
}

export const getSnackBar = (state) => {
  return state.snackBar
}

export const getNotificationDialog = (state) => {
  return state.notificationDialog
}

export const getFullName = (state) => {
  return `${state.userCommonData.name} ${state.userCommonData.surname}`
}

export const currentUser = (state) => {
  return state.currentUser
}

export const userCommonData = (state) => {
  return state.userCommonData
}

export const defaultLanguage = (state) => {
  return state.defaultLanguage
}

export const contacts = (state) => {
  return state.contacts
}

export const allCurrencies = state => {
  return state.currencies
}

export const countries = (state) => {
  return state.countries
}

export const helpConversations = (state) => {
  return state.helpConversations
}

export const titleNotificationInterval = (state) => {
  return state.titleNotificationInterval
}
