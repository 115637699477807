<template>
    <v-dialog v-model="dialog" width="550" persistent>
    <v-card>
      <v-card-title>{{ id ? $t('companies.groups.editTitle') : $t('companies.groups.addTitle') }}</v-card-title>
      <v-card-text>
        <label>{{ $t('companies.groups.name') }}</label>
        <v-text-field
          v-model="model.name"
          dense
          outlined
          :loading="loading"
          :disabled="loading"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn @click="save" color="primary" :loading="loading" depressed>
          {{ $t('companies.groups.save') }}
        </v-btn>
        <v-btn @click="close" color="primary" :loading="loading" outlined >
          {{ $t('users.dialogs.addNew.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Services from '@/services'
export default {
  data () {
    return {
      id: null,
      name: null,
      dialog: false,
      model: {
        groupId: null,
        name: null
      },
      loading: false
    }
  },
  methods: {
    open () {
      this.model = {}
      this.dialog = true
      if (this.id) {
        this.model.groupId = this.id
        this.model.name = this.name
      }
    },
    async save () {
      this.loading = true
      try {
        await Services.post(this.id ? 'Contact/UpdateGroup' : 'Contact/AddGroup', this.model)
      } finally {
        this.loading = false
      }
      this.$emit('save')
      this.close()
    },
    close () {
      this.dialog = false
    }
  }
}
</script>
