<template>
  <v-dialog v-model="dialog" scrollable max-width="1200">
    <v-card v-if="popupFile">
      <v-card-title>
        {{ popupFile.customName }}
        <v-spacer></v-spacer>
        <v-btn class="mr-2" color="primary" @click="download"
          ><v-icon class="mr-2"> uil uil-import</v-icon
          >{{ $t('common.download') }}</v-btn
        >
        <v-btn color="primary" icon @click="close"
          ><v-icon large>uil uil-times-circle</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="d-flex h-100">
        <v-img
          v-if="popupFile.url"
          class="ma-auto"
          :src="popupFile.url"
        ></v-img>

        <v-progress-circular
          class="ma-auto"
          v-else
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Services from '@/services'
export default {
  data () {
    return {
      dialog: false,
      popupFile: null
    }
  },
  props: {
    threadId: String
  },
  methods: {
    async open (file) {
      const response = await Services.getFile('Chat/GetChatFile', {
        threadId: this.threadId,
        fileName: file.originalName
      })
      const blob = new Blob([response.data])
      const fileUrl = URL.createObjectURL(blob)
      this.popupFile = { ...file, url: fileUrl }
      this.dialog = true
    },
    close () {
      this.popupFile = null
      this.dialog = false
    },
    async download () {
      const link = document.createElement('a')
      link.href = this.popupFile.url
      link.download = this.popupFile.customName + '.' + this.popupFile.extension
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
}
</script>

<style></style>
