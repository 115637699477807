import * as types from './notifications-mutation-types'
import { Howl } from 'howler'
import { getSoundSource } from '@/utils/helpers'

const getDefaultState = () => {
  return {
    notifications: [],
    soundPlaying: false
  }
}

const state = getDefaultState()

const getters = {
  notifications (state) {
    return state.notifications
  },
  countNewNotification (state) {
    return state.notifications.reduce((result, currentValue) => {
      if (currentValue.isRead === true) {
        return result
      }
      return result + 1
    }, 0)
  }
}

const actions = {
  playSound ({ state, commit }, soundType) {
    if (!soundType) {
      return
    }
    const sound = new Howl({
      src: getSoundSource(soundType)
    })

    if (!state.soundPlaying) {
      sound.play()
      commit(types.MARK_SOUND_AS_PLAYING, true)

      sound.on('end', () => {
        commit(types.MARK_SOUND_AS_PLAYING, false)
      })
    }
  },
  playSoundTest ({ state, commit }, soundType) {
    if (!soundType) {
      return
    }
    const sound = new Howl({
      src: getSoundSource(soundType)
    })
    sound.play()
    commit(types.MARK_SOUND_AS_PLAYING, true)
    sound.on('end', () => {
      commit(types.MARK_SOUND_AS_PLAYING, false)
    })
  },
  async addNewNotification ({ commit, dispatch }, notification) {
    await dispatch('playSound', notification.sound)
    commit(types.ADD_NOTIFICATION, notification)
  },
  resetState ({ commit }) {
    commit(types.RESET_STATE)
  }
}

const mutations = {
  [types.SET_NOTIFICATIONS] (state, notifications) {
    state.notifications = notifications
  },
  [types.MARK_SOUND_AS_PLAYING] (state, isPlaying) {
    state.soundPlaying = isPlaying
  },
  [types.ADD_NOTIFICATION] (state, notification) {
    state.notifications = [
      notification,
      ...state.notifications
    ]
  },
  [types.MARK_AS_READ_NOTIFICATION] (state, notificationId) {
    const notification = state.notifications.find(n => n.id === notificationId)

    if (!notification) {
      return
    }

    notification.isRead = true
  },
  [types.RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
