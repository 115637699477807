<template>
<div :class="classes" class="d-flex">
  <div class="pa-2 rounded text-wrap text-wrapper" :class="isCurrentUserSender ? 'ml-auto' : 'ml-1'">
    <div class="d-inline-flex align-center text-message">
      <div v-if="isCurrentUserSender">
        <span v-if="type === types.COUNTEROFFER">{{ $t('chatWindow.deal.yourCounterOffer') }}</span>
        <span v-else>{{ $t('chatWindow.deal.yourOffer') }}</span>
        {{ value.offer.price }} {{ currency }}
        <div class="body-2 lightText--text text-right">{{ value.creationDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</div>
      </div>
      <div v-else>
        <span v-if="type === types.COUNTEROFFER">{{ $t('chatWindow.deal.receivedCounterOffer') }}</span>
        <span v-else>{{ $t('chatWindow.deal.receivedOffer') }}</span>
        {{ value.offer.price }} {{ currency }}
        <div class="body-2 accent--text">{{ value.creationDate | dateFormat('YYYY-MM-DD HH:mm:ss') }}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import * as types from '@/types/message-types'
export default {
  components: {},
  props: {
    value: Object,
    threadId: String,
    idToUseAsCurrentUser: String
  },
  data () {
    return {
      types
    }
  },
  computed: {
    type () {
      return this.value?.type
    },
    currency () {
      return this.allCurrency.find((el) => el.id === this.value.offer?.currency)
        ?.description
    },
    allCurrency () {
      return this.$store.getters.enums.currency
    },
    isCurrentUserSender () {
      return this.currentUserId === this.value.sender
    },
    currentUserId () {
      if (this.idToUseAsCurrentUser) {
        return this.idToUseAsCurrentUser
      }
      return this.$store.getters.userCommonData.id
    },
    classes () {
      let classes = 'message'
      if (this.isCurrentUserSender) {
        classes += ' current-user-sender'
      }
      return classes
    },
    splitedValue () {
      return this.value.text.toString().split('\n')
    }
  },
  methods: {}
}
</script>
