import * as types from './users-mutation-types'
import Vue from 'vue'

const getDefaultState = () => {
  return {
    users: JSON.stringify([])
  }
}

const state = getDefaultState()

const getters = {
  users (state) {
    return JSON.parse(state.users).map(user => {
      Vue.set(user, 'fullName', `${user.name} ${user.surname}`)
      return user
    })
  }
}

const actions = {
  resetState ({ commit }) {
    commit(types.RESET_STATE)
  }
}

const mutations = {
  [types.SET_USERS] (state, users) {
    state.users = JSON.stringify(users)
  },
  [types.USER_CONNECTION] (state, userId) {
    const users = JSON.parse(state.users)
    const user = users.find(user => user.id === userId)
    if (!user) {
      return
    }
    user.isOnline = true
    state.users = JSON.stringify(users)
  },
  [types.USER_DISCONNECTION] (state, userId) {
    const users = JSON.parse(state.users)
    const user = users.find(user => user.id === userId)
    if (!user) {
      return
    }
    user.isOnline = false
    state.users = JSON.stringify(users)
  },
  [types.USER_UPDATE_OR_ADD] (state, payload) {
    const users = JSON.parse(state.users)
    const user = users.find(user => user.id === payload.id)
    if (user) {
      for (const property in user) {
        user[property] = payload[property]
      }
    } else {
      users.push(payload)
    }
    state.users = JSON.stringify(users)
  },
  [types.USER_STATE_CHANGE] (state, payload) {
    const users = JSON.parse(state.users)
    const user = users.find(user => user.id === payload.userId)

    if (user) {
      user.onlineStatus = payload.onlineStatus
    }
    state.users = JSON.stringify(users)
  },
  [types.RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
